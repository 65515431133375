import { Address, PartyType, ProductType, RepresentativeDto1 } from 'src/api/api'
import { PartyCountries } from './enums'
import dayjs, { Dayjs } from 'dayjs'

export interface Dictionary<T> {
  [index: string]: T
}

export function isDefined(obj?: any) {
  return obj !== null && obj !== undefined
}

export function isEmpty(obj?: any) {
  return obj === undefined || obj === null || obj === '' || Object.keys(obj).length === 0
}

export function nullIfEmpty(value: any) {
  return isEmpty(value) ? null : value
}

export function formatPerc(value?: string | number, decimalPlaces = 2) {
  return formatDecimal(value, decimalPlaces) + ' %'
}

export function formatMoney(value?: string | number, digits = 0, locales = 'cs-CS') {
  if (value === null || value === undefined || value === '') return ''

  let currency
  if (locales === 'cs-CS') currency = 'CZK'

  return (+value).toLocaleString(locales, {
    style: 'currency',
    currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
}

export function formatMoneyWithCurrency(value: string | number, currency: string, digits = 0, locales = 'cs-CS') {
  if (value === null || value === undefined || value === '') return ''

  const numericValue = Number(value)
  if (isNaN(numericValue)) {
    console.warn(`Numeric value is a NaN: ${value}`)
    return String(value)
  }

  try {
    //Validate currency, if it is invalid, return the input value
    new Intl.NumberFormat(locales, { style: 'currency', currency: currency })
  } catch {
    return String(value)
  }

  const formatter = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })

  return formatter.format(numericValue)
}

export function formatDecimal(value?: string | number, digits = 0, locales = 'cs-CS') {
  if (value === null || value === undefined || value === '') return ''

  let currency
  if (locales === 'cs-CS') currency = 'CZK'

  return (+value).toLocaleString(locales, {
    currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
}

export function orElse(value: any, valueIfNull: any) {
  return value === null || value === undefined ? valueIfNull : value
}

export function formatBoolean(value?: boolean, nullAsFalse = false) {
  if (value === null || value === undefined) return ''

  return value === true ? 'Yes' : nullAsFalse ? 'No' : ''
}

export function formatDate(value?: Date | string, withTime = false) {
  if (value === null || value === undefined) return ''

  const d = dayjs(value)

  const dateFormat = 'DD.MM.YYYY'

  let result = d.format(dateFormat)

  if (withTime) {
    const timeFormat = ' HH:mm:ss'
    result += d.format(timeFormat)
  }

  return result
}

export const formatDateTime = (value?: Date | string) => formatDate(value, true)

export enum ProductTypeLabel {
  M1 = '1 month',
  M3 = '3 months',
  M3R = '3 months',
  M6 = '6 months',
  M12 = '12 months'
}

export function formatProductType(value?: ProductType) {
  if (value === null || value === undefined) return ''

  return ProductTypeLabel[value]
}

export function formatAddress(value?: Address) {
  if (value === null || value === undefined) return ''

  const items = []

  if (value.line1) items.push(value.line1)
  if (value.line2) items.push(value.line2)

  if (value.zip || value.city) {
    items.push((value.zip ? value.zip : '') + (value.zip && value.city ? ' ' : '') + (value.city ? value.city : ''))
  }

  if (value.country) items.push(value.country)

  return items.join(', ')
}

export function parseApiError(err: any): { message: string; logref?: string } {
  let message = 'Something went wrong'
  let logref

  if (err.response && [400, 422].includes(err.response.status)) {
    if (err.response.data) {
      if (err.response.data.logref) {
        logref = err.response.data.logref
      }

      if (err.response.data['_embedded'] && err.response.data['_embedded'].errors) {
        const errors = err.response.data['_embedded'].errors.map((it: any) => it.message)
        message = errors.join(', ')
      }
    }
  }

  return { message: message, logref: logref }
}

export function formatPartyFullname(party?: any) {
  if (isEmpty(party)) return ''

  if (party.type == PartyType.PERSON) {
    return party.fullName
  } else {
    return party.orgNameOrRegNum
  }
}

export const sum = (nums: Array<number>) => nums.reduce((a: number, b: number) => 1 * a + 1 * b, 0)

export const avg = (nums: Array<number>) => sum(nums) / nums.length

export const addMonths = (date: Date, months: number) => {
  const newDate = new Date(date)
  const day = newDate.getDate()
  newDate.setMonth(newDate.getMonth() + +months)
  if (newDate.getDate() != day) newDate.setDate(0)

  return newDate
}

export const transformDataForDataTree = (data: Array<RepresentativeDto1>, showInactiveRepresentatives: boolean) => {
  const transformedData = new Map()

  data.forEach(item => {
    if (!transformedData.has(item.id)) {
      transformedData.set(item.id, { ...item })
    } else {
      transformedData.set(item.id, { ...transformedData.get(item.id), ...item })
    }

    if (item.userParties && item.userParties.length > 0) {
      item.userParties.forEach((up: any) => {
        if (!transformedData.has(up.userParty.id)) {
          transformedData.set(up.userParty.id, {
            id: up.userParty.id,
            effectiveFrom: item.effectiveFrom,
            onBehalf: item.onBehalf,
            roleText: item.roleText,
            statutory: item.statutory,
            version: item.version,
            party: { ...up.userParty },
            linked: true,
            state: up.state
          })
        } else {
          const existingParty = transformedData.get(up.userParty.id)
          transformedData.set(up.userParty.id, {
            ...existingParty,
            effectiveFrom: item.effectiveFrom,
            onBehalf: item.onBehalf,
            roleText: item.roleText,
            statutory: item.statutory,
            version: item.version,
            party: { ...up.userParty },
            linked: true,
            state: up.state
          })
        }
      })
    }
  })

  let resultArray = Array.from(transformedData.values())

  if (!showInactiveRepresentatives) {
    resultArray = resultArray.filter(item => {
      // Keep items where effectiveTo is not provided, so rep is active or the date is in the future
      return !item.effectiveTo || (item.effectiveTo && dayjs(item.effectiveTo).isAfter(dayjs()))
    })
  }

  return resultArray
}

export const getLocalDate = (dt?: Date | Dayjs | null) => {
  if (!dt) return undefined
  return dayjs(dt).format('YYYY-MM-DD')
}

export const formatMonthYear = (date: Date | string) => {
  if (typeof date === 'string') return 0

  return date.getMonth() + 1 + '/' + date.getFullYear()
}

export const sortByCountryAndUserParties = (arr: Array<RepresentativeDto1>) => {
  return arr.sort((a, b) => {
    const countryA = a.party?.country || PartyCountries._U
    const countryB = b.party?.country || PartyCountries._U
    const hasUserPartiesA = a.userParties?.length > 0
    const hasUserPartiesB = b.userParties?.length > 0

    if (countryA !== PartyCountries._U && countryB === PartyCountries._U) {
      return -1
    } else if (countryA === PartyCountries._U && countryB !== PartyCountries._U) {
      return 1
    }

    if (hasUserPartiesA && !hasUserPartiesB) {
      return -1
    } else if (!hasUserPartiesA && hasUserPartiesB) {
      return 1
    }

    return 0
  })
}

export const differenceInDays = (dt1: Date, dt2: Date) => {
  return Math.floor((dt2.getTime() - dt1.getTime()) / (1000 * 3600 * 24))
}

export const round2 = (num: number) => Math.round(num * 100 + Number.EPSILON) / 100

export const intersection = (a: Array<string>, b: Array<string>) => a.filter(x => b.includes(x))

export const isAuthorized = (user: any, role: string) =>
  user[`${process.env.NEXT_PUBLIC_API_AUDIENCE}/roles`].includes(role)

export const formatRegNumWithCountryCode = (countryCode: string, regNum: string) => {
  return `${countryCode}-${regNum}`
}

export const calculateMonthsDifference = (fromDate: Date | string, tillDate: Date | string) => {
  if (!fromDate || !tillDate) return 0

  const from = new Date(fromDate)
  const till = new Date(tillDate)

  // Calculate the difference in months
  const yearsDifference = till.getFullYear() - from.getFullYear()
  const monthsDifference = till.getMonth() - from.getMonth() + 1

  return yearsDifference * 12 + monthsDifference
}

export function debounce<T extends (...args: any[]) => any>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>

  return function executedFunction(...args: Parameters<T>): void {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}
