import { Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  const domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || "";
  const tenant = process.env.NEXT_PUBLIC_AUTH0_TENANT || "";
  const clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || "";
  const audience = process.env.NEXT_PUBLIC_API_AUDIENCE || "";
	const redirectUri = process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI || "";

  // const history = useHistory();

  // const onRedirectCallback = (appState) => {
  //   history.push(appState?.returnTo || window.location.pathname);
  // };

  return (
    <Auth0Provider
      domain={domain}
      // issuer={issuer ? issuer : domain}
      clientId={clientId}
      audience={audience}
      redirectUri={redirectUri}
      // onRedirectCallback={onRedirectCallback}
      // useRefreshTokens={true}
      // useCookiesForTransactions={true}
			cacheLocation="localstorage"
      authorizationParams={{
        audience: `https://${tenant}/api/v2/`,
        screen_hint: 'login', // 'signup'
        redirect_uri: tenant
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
