// ** React Imports
import { ReactNode, useEffect, useState } from 'react'
import Error401 from './401'

// ** Next Imports
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** @mui/x imports
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// ** Config Imports

import themeConfig from 'src/configs/themeConfig'

// ** Third Party Import
import toast, { Toaster } from 'react-hot-toast'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import WindowWrapper from 'src/@core/components/window-wrapper'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Styled Components
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Global css styles
import '../../styles/globals.css'

// ** Global dayjs configs

import '../configs/dayjs'

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

import { LicenseInfo } from '@mui/x-license'
import AuthProvider from 'src/common/auth-provider'
import { useAuth0 } from '@auth0/auth0-react'
import { auth } from 'src/common/auth-wrapper'

LicenseInfo.setLicenseKey(
  'e0cbcb1aebfddd4b6ce6a3e7bce4f095Tz0xMDI2OTEsRT0xNzY0NDI0ODU1MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
)

import { init as initApm } from '@elastic/apm-rum'
import Onboarding from './onboarding'

const apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'myFlowpayAdmin',
  environment: process.env.NEXT_PUBLIC_FP_ENV,

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: 'https://426da55542154f44a9cecd3269e0d750.apm.westeurope.azure.elastic-cloud.com:443',

  // Set service version (required for sourcemap feature)
  serviceVersion: ''
})

interface AuthGuardProps {
  children: ReactNode
}

const Guard = (props: AuthGuardProps) => {
  const { children } = props
  const router = useRouter()
  const { getAccessTokenSilently, isLoading, isAuthenticated, error, user, getIdTokenClaims } = useAuth0()
  const [isInit, setIsInit] = useState<boolean>(true)
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
  const [roles, setRoles] = useState<string[]>([])

  auth.setAccessTokenSilently(getAccessTokenSilently)

  // console.log(isLoading, 'isLoading')
  // console.log(user, 'user')

  useEffect(() => {
    if (error) {
      toast.error(error.message)
      router.push('/', undefined, { shallow: true })
    }
  }, [error, router])

  useEffect(() => {
    if (isLoading) return

    if (user) {
      const roleKey = `${process.env.NEXT_PUBLIC_API_AUDIENCE}/roles`
      const roles = user[roleKey]
      setRoles(roles)
      setIsAuthorized(Array.isArray(roles) && roles.length > 0)
    }

    setIsInit(false)
  }, [user, isLoading])

  if (isLoading || isInit) {
    return <Spinner />
  }

  if (!isAuthenticated) {
    return <Error401 />
  }

  if (!isAuthorized) {
    return <Onboarding />
  }

  return <>{children}</>
}

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const getLayout = Component.getLayout ?? (page => <UserLayout>{page}</UserLayout>)

  const setConfig = Component.setConfig ?? undefined

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{themeConfig.templateName}</title>
        <meta name='description' content={themeConfig.templateName} />
        <meta name='keywords' content='FLOWPAY' />
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Head>

      <AuthProvider>
        <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
          <SettingsConsumer>
            {({ settings }) => {
              return (
                <ThemeComponent settings={settings}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <WindowWrapper>
                      <Guard>{getLayout(<Component {...pageProps} />)}</Guard>
                    </WindowWrapper>
                    <ReactHotToast>
                      <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                    </ReactHotToast>
                  </LocalizationProvider>
                </ThemeComponent>
              )
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </AuthProvider>
    </CacheProvider>
  )
}

export default App
