import { useAuth0 } from '@auth0/auth0-react'
import { ReactNode } from 'react'
import { NavLink } from 'src/@core/layouts/types'
import { intersection } from 'src/common/core'

interface Props {
  navLink?: NavLink
  children: ReactNode
}

const CanViewNavLink = (props: Props) => {
  const { navLink, children } = props
	const { user } = useAuth0();

	if (!user) return null;

	var roleKey = `${process.env.NEXT_PUBLIC_API_AUDIENCE}/roles`;
	var roles = user[roleKey];
	// console.log(roles[0], 1)
	// console.log(navLink.roles, 2)
	// console.log(intersection(navLink.roles, roles), 3)

  if ((navLink && navLink.roles && Array.isArray(navLink.roles) && (intersection(navLink.roles, roles).length > 0))) {
    return <>{children}</>
  } else {
    return null
  }
}

export default CanViewNavLink