import { SvgIconProps } from '@mui/material/SvgIcon'
import CustomSvgIcon from './custom-svg-icon'

const FolderInformationOutline = (props: SvgIconProps) => {
  return (
    <CustomSvgIcon
      {...props}
      path='M21 11.1V8C21 6.9 20.1 6 19 6H11L9 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H10.2C11.4 21.8 13.6 23 16 23C19.9 23 23 19.9 23 16C23 14.1 22.2 12.4 21 11.1M9.3 18H3V8H19V9.7C18.1 9.2 17.1 9 16 9C12.1 9 9 12.1 9 16C9 16.7 9.1 17.4 9.3 18M16 21C13.2 21 11 18.8 11 16S13.2 11 16 11 21 13.2 21 16 18.8 21 16 21M17 14H15V12H17V14M17 20H15V15H17V20Z'
    />
  )
}

export default FolderInformationOutline
