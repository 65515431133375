import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// Define a new prop type that extends SvgIconProps and includes a `path` prop
interface CustomSvgIconProps extends SvgIconProps {
  path: string;
}

const CustomSvgIcon: React.FC<CustomSvgIconProps> = ({ path, ...svgIconProps }) => {
  return (
    <SvgIcon {...svgIconProps}>
      <path d={path} />
    </SvgIcon>
  );
};

export default CustomSvgIcon;
