// ** Icon imports
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import AccountOutline from '@mui/icons-material/PermIdentityOutlined'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'

import OfferIcon from 'src/common/custom/icons/offer-icon'
import CardAccountDetailsOutline from 'src/common/custom/icons/card-account-details-outlined'
import AccountTie from 'src/common/custom/icons/account-tie'
import FolderInformationOutline from 'src/common/custom/icons/folder-information-outline'
import AccountCashOutline from 'src/common/custom/icons/account-cash-outline'
import Cash from 'src/common/custom/icons/cash'

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: 'Dashboard',
      icon: HomeOutlinedIcon,
      roles: ['Admin'],
      path: '/dashboard'
    },
    {
      title: 'Offers',
      icon: OfferIcon,
      roles: ['Admin'],
      path: '/offers'
    },
    {
      title: 'Leads',
      icon: CardAccountDetailsOutline,
      roles: ['Admin', 'CC'],
      path: '/leads'
    },
    {
      title: 'Financings',
      icon: QrCodeScannerIcon,
      roles: ['Admin', 'CC'],
      path: '/financings'
    },
    {
      title: 'Collections',
      icon: LocalPoliceOutlinedIcon,
      roles: ['Admin'],
      path: '/collections'
    },
    {
      title: 'Customers',
      icon: CorporateFareIcon,
      roles: ['Admin'],
      path: '/customers'
    },
    {
      title: 'Blacklists',
      icon: LockOutlinedIcon,
      roles: ['Admin'],
      path: '/blacklists'
    },
    {
      title: 'Persons & Orgs',
      icon: AccountTie,
      roles: ['Admin'],
      path: '/parties'
    },
    // {
    //   title: 'Prolongations',
    //   icon: FileSign,
    //   roles: ['Admin'],
    //   path: '/prolongations'
    // },
    {
      title: 'Installments',
      icon: Cash,
      roles: ['Admin'],
      path: '/installments'
    },
    {
      title: 'Transactions',
      icon: AccountBalanceIcon,
      roles: ['Admin'],
      path: '/transactions'
    },
    {
      title: 'Partners',
      icon: HandshakeOutlinedIcon,
      roles: ['Admin'],
      path: '/partners'
    },
    {
      title: 'Commissions',
      icon: AccountCashOutline,
      roles: ['Admin'],
      path: '/commissions'
    },
    {
      icon: FolderInformationOutline,
      title: 'Terms',
      roles: ['Admin'],
      path: '/terms'
    },
    // {
    //   title: 'Users',
    //   icon: AccountOutline,
    //   roles: ['admin'],
    //   path: '/users'
    // },
    {
      title: 'Audit Log',
      icon: AccountOutline,
      roles: ['Admin'],
      path: '/audit-log'
    }
  ]
}

export default navigation
