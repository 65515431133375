import { Dictionary } from './core'

export enum Currencies {
  CZK = 'CZK',
  EUR = 'EUR'
}

export enum PartyCountries {
  CZ = 'CZ',
  SK = 'SK',
  _U = '_U'
}

export const PartyCountriesMap: Dictionary<string> = {
  CZ: 'CZ',
  SK: 'SK',
  _U: 'USER'
}

export enum RepresentativeType {
  'USER' = 'USER',
  'REG' = 'REG'
}

export enum Channel {
  SMS = 'SMS',
  Email = 'E-mail',
  Phone = 'Phone',
  DataMailbox = 'Data mailbox',
  Legal = 'Legal',
  Other = 'Other',
}

export enum ReportType {
  TXN = 'TXN',
  AML = 'AML',
  TXN_DETAIL = 'TXN_DETAIL',
  TXN_DASHBOARD = 'TXN_DASHBOARD'
}

// export const getRepType = (party: Party, linked: boolean, verified: boolean, user: any) =>
//   party?.country == PartyCountries._U
//     ? (linked ? '↳ ' : '') + RepresentativeType.USER + ' (' + orElse(party?.partnerCode, 'Flowpay') + ')' + (verified ? ' ✓' : ' ✗')
//     : RepresentativeType.REG
