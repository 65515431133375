// ** MUI Imports
import { Theme } from '@mui/material/styles'

const TextField = (theme: Theme) => {
  return {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true
        },
        FormHelperTextProps: {
          style: {
            margin: '0'
          }
        }
      }
    }
  }
}

export default TextField
