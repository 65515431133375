/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Address {
  line1?: string | null
  line2?: string | null
  city: string
  zip: string
  country: string
}

export enum AddressType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL'
}

export interface AggregatedRiskGradeDto {
  /** @format int32 */
  riskGradePartner?: number
  /** @format int32 */
  riskGradeOffer?: number
  /** @format int32 */
  forecastedRevenue?: number
  /** @format int32 */
  maxLoanLimit?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
}

export type Application = BaseIdEntity &
  BaseMetadataEntity & {
    active?: boolean
    /** @format uuid */
    customerId: string
    /** @format uuid */
    offerId: string
    /** @format uuid */
    leadId: string
    state: ApplicationState
    inputState: ApplicationInputState
    /** @format date-time */
    revisionListDownloadedAt?: string
    amlState: ApplicationAmlState
    /** @format date-time */
    amlStateUpdatedAt?: string
    /** @format uuid */
    rep1Id: string
    /** @format uuid */
    rep2Id?: string
    requested: ProductParams
    purpose?: string
    product?: ProductParams
    pep?: boolean
    iban?: string
    psdSupported?: boolean | null
    /** @format date-time */
    submittedAt?: string
    /** @format date-time */
    rejectedAt?: string
    /** @format date-time */
    approvedAt?: string
    /** @format date-time */
    contractCreatedAt?: string
    /** @format date-time */
    contractSentAt?: string
    /** @format date-time */
    contractSignedAt?: string
    contractRefSigni?: string
    rep1ContractSignUrl?: string
    rep2ContractSignUrl?: string
    externalRef?: string
    /** @format uuid */
    termsId?: string | null
    revenues?: ApplicationRevenues
    rejectionReason?: ApplicationRejectionReason
    /** @format uuid */
    approvalBy?: string
    /** @format uuid */
    reviewedBy?: string
    /** @format date-time */
    reviewedAt?: string
  }

export interface ApplicationAdminListItemDto {
  /** @format uuid */
  id?: string
  /** @format uuid */
  offerId?: string
  state?: FinancingState
  /** @format uuid */
  customerId?: string
  customerName?: string
  regNum?: string
  country?: string
  partnerCode?: string
  type?: ProductType
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  ratePerc?: number
  externalRef?: string
  debtProviderName?: string | null
  servicingActions?: ServicingActionType[]
  collectionState?: CollectionActionType
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  updatedAt?: string
}

export enum ApplicationAmlState {
  PENDING = 'PENDING',
  MANUAL_CHECK = 'MANUAL_CHECK',
  KO = 'KO',
  SUCCESS = 'SUCCESS',
  EXPIRED = 'EXPIRED',
  ALT_CHECK_PENDING = 'ALT_CHECK_PENDING',
  ALT_CHECK_REQUESTED = 'ALT_CHECK_REQUESTED'
}

export enum ApplicationApprovalAction {
  APPROVE = 'APPROVE',
  APPROVE_CHANGED_TERMS = 'APPROVE_CHANGED_TERMS',
  REJECT = 'REJECT'
}

export interface ApplicationApprovalDto {
  action?: ApplicationApprovalAction
  params?: ProductParams
  note?: string
  rejectionReason?: ApplicationRejectionReason
}

export interface ApplicationDetailDto {
  application: Application
  reviewedByUser?: string | null
  approvalByUser?: string | null
  collectionState?: CollectionActionType
}

export enum ApplicationInputState {
  REP2 = 'REP2',
  PEP = 'PEP',
  UBO = 'UBO',
  REVENUE = 'REVENUE',
  BANK = 'BANK',
  PSD = 'PSD',
  KYC = 'KYC',
  DONE = 'DONE'
}

export enum ApplicationRejectionReason {
  ACCOUNT_DATA_DISCREPANCY = 'ACCOUNT_DATA_DISCREPANCY',
  DANGEROUS_ACCOUNT_TRANSACTIONS = 'DANGEROUS_ACCOUNT_TRANSACTIONS',
  KO_CRITERIA = 'KO_CRITERIA',
  LOAN_PURPOSE_NOT_SUPPORTED = 'LOAN_PURPOSE_NOT_SUPPORTED',
  LOW_COMPANY_TURNOVER = 'LOW_COMPANY_TURNOVER',
  NOT_SUFFICIENT_DOCUMENTS = 'NOT_SUFFICIENT_DOCUMENTS',
  RESTRICTED_COMPANY_BUSINESS = 'RESTRICTED_COMPANY_BUSINESS',
  SHORT_COMPANY_HISTORY = 'SHORT_COMPANY_HISTORY',
  BLACKLIST_FOUND = 'BLACKLIST_FOUND',
  OTHER = 'OTHER',
  OVER_INDEBTED = 'OVER_INDEBTED',
  INSUFFICIENT_FUNDING = 'INSUFFICIENT_FUNDING'
}

export interface ApplicationRevenues {
  revenues3MonthAvg?: number | null
  expenses3MonthAvg?: number | null
  repaymentsMonthAvg?: number | null
  revenuesYear?: number | null
  expensesYear?: number | null
  currency?: IsoCurrencyCode
}

export enum ApplicationState {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  SENT_FOR_APPROVING = 'SENT_FOR_APPROVING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED'
}

export interface ApplicationSubmitBankDto {
  /**
   * Bank account number
   * @example CZ6508000000192000145399
   */
  iban: string
}

export interface ApplicationSubmitRep2Dto {
  /** @format uuid */
  id: string
  /** @format email */
  email: string
}

export interface ApplicationWithRevenuesAdminListItemDto {
  /** @format uuid */
  id?: string
  /** @format uuid */
  offerId?: string
  state?: FinancingState
  /** @format uuid */
  customerId?: string
  customerName?: string
  regNum?: string
  partnerCode?: string
  type?: ProductType
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  ratePerc?: number
  revenues3MonthAvg?: number | null
  expenses3MonthAvg?: number | null
  repaymentsMonthAvg?: number | null
  revenuesYear?: number | null
  expensesYear?: number | null
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  updatedAt?: string
  /** @format int32 */
  riskGrade?: number | null
  /** @format date-time */
  disbursedAt?: string | null
  installment?: number | null
  /** @format int32 */
  maxDaysOverdue?: number | null
  /** @format date */
  lastInstallment?: string | null
  /** @format int32 */
  overdueInstallmentCount?: number | null
  outstandingPrincipal?: number
}

export interface AuditLogEmbeddedEntryDto {
  /** @format int64 */
  auditLogId: number
  /** @format uuid */
  entityId: string
  /** @format date-time */
  timestamp: string
  username: string
  /** @format int64 */
  version: number
  previousValuesJson?: string | null
  updatedValuesJson?: string | null
  oldRow?: string | null
}

export interface AuditLogEntry {
  entityName?: string
  /** @format int64 */
  id?: number
  /** @format int64 */
  tableOid?: number
  action: string
  rowIdOld?: string
  rowId?: string
  /** @format int64 */
  version?: number
  /** @format date-time */
  timestampTx: string
  /** @format date-time */
  timestampStm: string
  /** @format date-time */
  timestampClk: string
  userId?: string
  /** @format uuid */
  userUuid?: string
  username?: string
  traceId?: string
  clientAddr?: string
  /** @format int32 */
  clientPort?: number
  /** @format int32 */
  clientQueryLen?: number
  clientQuery?: string
  sessionUserName?: string
  appName?: string
  /** @format int64 */
  txId?: number
  statementOnly?: boolean
  rowOld?: string
  rowDiff?: string
}

export type BankAccount = BaseIdEntity &
  BaseMetadataEntity & {
    iban?: string
    /** @format uuid */
    bankId?: string
    /** @format date-time */
    effectiveFrom?: string
    /** @format date-time */
    effectiveTo?: string
    owner?: string
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency?: IsoCurrencyCode
    /** Countries supported by FlowPay */
    forCountry?: FlowpayCountry
  }

export interface BankDto {
  /** @format uuid */
  id: string
  bic: string
  code: string
  name: string
  active: boolean
  psdSupported?: boolean
  country: string
}

export type BankTransaction = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format date-time */
    processedAt?: string
    iban: string
    state?: BankTransactionState
    /** @format date */
    date: string
    amount: number
    balance?: number
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency: IsoCurrencyCode
    txnCode?: string | null
    bankRef?: string | null
    vs?: string | null
    counterPartyIban?: string | null
    counterPartyName?: string | null
    counterPartyComment?: string | null
    internalComment?: string | null
    userId?: string | null
    /** @format uuid */
    loanId?: string | null
    /** @format uuid */
    partnerId?: string | null
  }

export enum BankTransactionState {
  NEW = 'NEW',
  PAIRED = 'PAIRED',
  MANUAL_PAIRING = 'MANUAL_PAIRING',
  IGNORED = 'IGNORED'
}

export type BaseIdEntity = BaseMetadataEntity & {
  /** @format uuid */
  id?: string
}

export interface BaseMetadataEntity {
  /** @format int64 */
  version?: number
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  updatedAt?: string
}

export type BasePartyOrganizationEntity = BaseMetadataEntity & {
  id?: PartyOrganizationId
  /** @format date-time */
  effectiveFrom: string
  /** @format date-time */
  effectiveTo?: string
  /** @format int32 */
  partyRank?: number
  /** @format int32 */
  organizationRank?: number
}

export interface BlacklistDto {
  /** @format uuid */
  id: string
  type: BlacklistType
  /** @format uuid */
  customerId: string
  /** @format uuid */
  userId?: string
  /** @format int32 */
  effectivePeriodMonths?: number
  /** @format date */
  effectiveFrom?: string
  /** @format date */
  effectiveTo?: string
  note?: string
  /** @format int64 */
  version?: number
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  updatedAt?: string
  reasonCode?: BlacklistReasonCode
}

export type BlacklistListDto = BlacklistDto & {
  userEmail?: string | null
  customerName: string
}

export enum BlacklistReasonCode {
  FRAUDULENT_PARTY = 'FRAUDULENT_PARTY',
  TERRORIST_PARTY = 'TERRORIST_PARTY',
  INTERNAL_BLACKLIST = 'INTERNAL_BLACKLIST',
  ACCOUNT_TAKEOVER = 'ACCOUNT_TAKEOVER',
  ID_FRAUD = 'ID_FRAUD',
  RISK_DECISION = 'RISK_DECISION',
  UNDESIRABLE_INFORMATION = 'UNDESIRABLE_INFORMATION',
  HARD_COLLECTIONS_CASE = 'HARD_COLLECTIONS_CASE',
  SEVERE_OVERDUE = 'SEVERE_OVERDUE'
}

export enum BlacklistType {
  TEMPORARY = 'TEMPORARY',
  PERMANENT = 'PERMANENT'
}

export interface CategoryAmounts {
  category?: string
  credit?: number
  debit?: number
  /** @format int64 */
  txCount?: number
}

export type CategorySentimentAmounts = CategoryAmounts & {
  sentiment?: string
}

export type Collection = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    loanId: string
    active: boolean
  }

export type CollectionAction = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    collectionId: string
    type: CollectionActionType
  }

export type CollectionActionDto = CollectionAction &
  BaseIdEntity &
  BaseMetadataEntity & {
    notes?: CollectionActionNoteDto[]
  }

export type CollectionActionNote = BaseIdEntity &
  BaseMetadataEntity & {
    note: string
    /** @format date-time */
    dateTime: string
  }

export type CollectionActionNoteDto = CollectionActionNote &
  BaseIdEntity &
  BaseMetadataEntity & {
    userName?: string
  }

export enum CollectionActionType {
  FORCE_COLLECTION = 'FORCE_COLLECTION',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  BUSINESS_NEGOTIATION = 'BUSINESS_NEGOTIATION',
  HANDED_LEGAL = 'HANDED_LEGAL',
  DEFAULT_NOTICE = 'DEFAULT_NOTICE',
  EXTERNAL_COLLECTION_PROCESS = 'EXTERNAL_COLLECTION_PROCESS',
  LEGAL_NEGOTIATION = 'LEGAL_NEGOTIATION',
  PRELITIGATION_NOTICE_CUSTOMER = 'PRELITIGATION_NOTICE_CUSTOMER',
  PRELITIGATION_NOTICE_GUARANTOR = 'PRELITIGATION_NOTICE_GUARANTOR',
  LEGAL_ACTION = 'LEGAL_ACTION',
  LEGAL_ACTION_DISPUTED = 'LEGAL_ACTION_DISPUTED',
  INSOLVENCY_ACTION = 'INSOLVENCY_ACTION',
  FORECLOSURE = 'FORECLOSURE',
  PAID_COLLECTION = 'PAID_COLLECTION',
  RESTRUCTURED = 'RESTRUCTURED',
  INSOLVENCY_CUSTOMER = 'INSOLVENCY_CUSTOMER',
  INSOLVENCY_GUARANTOR = 'INSOLVENCY_GUARANTOR',
  FORECLOSURE_CUSTOMER = 'FORECLOSURE_CUSTOMER',
  FORECLOSURE_GUARANTOR = 'FORECLOSURE_GUARANTOR'
}

export type CollectionActionWithInitialNoteDto = CollectionAction &
  BaseIdEntity &
  BaseMetadataEntity & {
    note: CollectionActionNote
  }

export type CollectionFee = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    collectionId: string
    type: CollectionFeeCollectionFeeType
    state: CollectionFeeCollectionFeeState
    /** @format date */
    effectiveDate: string
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency: IsoCurrencyCode
    total: number
    /** @format date */
    paidDate?: string | null
    paidTotal?: number | null
    vs?: string | null
    counterPartyIban?: string | null
  }

export enum CollectionFeeCollectionFeeState {
  OVERDUE = 'OVERDUE',
  PAID = 'PAID'
}

export enum CollectionFeeCollectionFeeType {
  COURT_FEE_FLOWPAY = 'COURT_FEE_FLOWPAY',
  COURT_FEE_CLIENT = 'COURT_FEE_CLIENT',
  LEGAL_REPRESENTATION = 'LEGAL_REPRESENTATION'
}

export interface CollectionFeeDto {
  /** @format uuid */
  id: string
  /** @format uuid */
  collectionId: string
  type: CollectionFeeCollectionFeeType
  state: CollectionFeeCollectionFeeState
  /** @format date */
  effectiveDate: string
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
  total: number
  /** @format date */
  paidDate: string
  paidTotal: number
  vs: string
  counterPartyIban: string
  /** @format int64 */
  version: number
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface CollectionRiskProfileTag {
  empty?: boolean
}

export enum CommissionState {
  NEW = 'NEW',
  PAID = 'PAID',
  PENDING = 'PENDING'
}

export interface CommissionAdminDto {
  customerName?: string
  /** @format uuid */
  id?: string
  /** @format uuid */
  loanId?: string
  /** @format uuid */
  partnerId?: string
  /** @format date */
  effectiveDate?: string
  /** @format uuid */
  statementId?: string
  state?: CommissionState
  /** @format uuid */
  installmentId?: string
  installmentState?: InstallmentInstallmentState
  /** @format date */
  installmentDueDate?: string
  /** @format date */
  installmentPaidAt?: string
  /** @format date */
  loanCollectionAt?: string
  /** @format date */
  paidAt?: string
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  installmentFee?: number
  installmentProlongationFee?: number
  installmentPenaltyFee?: number
  installmentFeeTotal?: number
  loanPrincipal?: number
  automaticDecrease?: boolean
  loanState?: LoanLoanState
  /** @format int64 */
  version?: number
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  updatedAt?: string
}

export type CommissionSchema = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    partnerId?: string
    /** @format date */
    effectiveFrom?: string
    /** @format date */
    effectiveTo?: string
    basePerc?: number
    prolongationPerc?: number
    penaltyPerc?: number
    pastDueDecreasePerc?: number
    product?: ProductType
    payment?: CommissionSchemaPaymentType
    calculation?: CommissionSchemaCalculationType
  }

export enum CommissionSchemaCalculationType {
  FLAT = 'FLAT',
  PA = 'PA'
}

export enum CommissionSchemaPaymentType {
  INSTALMENT_REPAYMENT = 'INSTALMENT_REPAYMENT',
  LOAN_REPAYMENT = 'LOAN_REPAYMENT',
  FIRST_INSTALLMENT = 'FIRST_INSTALLMENT',
  SHOPTET = 'SHOPTET'
}

export interface CommissionSchemaUpdateDto {
  /** @format uuid */
  id?: string
  /** @format date */
  effectiveFrom?: string
  /** @format date */
  effectiveTo?: string
  basePerc?: number
  prolongationPerc?: number
  penaltyPerc?: number
  pastDueDecreasePerc?: number
  product?: ProductType
  payment?: CommissionSchemaPaymentType
  calculation?: CommissionSchemaCalculationType
  /** @format int64 */
  version?: number
}

export enum CommissionStatementCommissionStatementState {
  PENDING = 'PENDING',
  PAID = 'PAID',
  NEW = 'NEW'
}

export interface CommunicationHistoryEntryDto {
  /** @format uuid */
  id?: string
  /** @format uuid */
  sourceEntityId?: string
  source?: CommunicationHistoryEntryDtoCommunicationHistoryEntrySource
  channel?: string
  description?: string
  /** @format date-time */
  createdAt?: string
  createdByUsername?: string
}

export enum CommunicationHistoryEntryDtoCommunicationHistoryEntrySource {
  COLLECTION = 'COLLECTION',
  FINANCING = 'FINANCING',
  LEAD = 'LEAD',
  GENERAL = 'GENERAL'
}

export type Contact = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format date-time */
    effectiveAt: string
    /** @format uuid */
    applicationId?: string
    /** @format uuid */
    customerId?: string
    /** @format uuid */
    leadId?: string
    channel: string
    message?: string
    /** @format uuid */
    updatedBy?: string
  }

export interface ContactAdminDto {
  /** @format uuid */
  id: string
  /** @format date-time */
  effectiveAt: string
  channel: string
  message: string
  /** @format uuid */
  updatedBy?: string
  username?: string
  /** @format int64 */
  version?: number
  /** @format uuid */
  applicationId?: string
  /** @format uuid */
  customerId?: string
  /** @format uuid */
  leadId?: string
}

export interface ConvertBlacklistDto {
  /** @format uuid */
  blacklistId: string
  reasonCode: BlacklistReasonCode
}

export interface CounterPartyAmountSum {
  counterName?: string
  counterAccount?: string
  amountSum?: number
}

export interface CounterPartyTxCount {
  counterName?: string
  counterAccount?: string
  /** @format int64 */
  txCount?: number
}

export interface CountryDto {
  /**
   * Two-letter country code as defined by ISO 3166-1 alpha-2
   * @example CZ
   */
  code?: string
  name: string
  /** National phone code */
  phoneCode?: string
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode
}

export interface CreateBankTransactionDto {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  amount: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
  vs?: string | null
  iban: string
  txnCode?: string | null
  bankRef?: string | null
  counterPartyIban?: string | null
  counterPartyName?: string | null
  counterPartyComment?: string | null
  internalComment?: string | null
}

export interface CreateBlacklistDto {
  type: BlacklistType
  /**
   * @format int32
   * @min 3
   * @max 12
   */
  effectivePeriodMonths: number
  note: string
  reasonCode: BlacklistReasonCode
}

export interface CreateCustomerRepDto {
  firstName: string
  lastName: string
  /** @format date */
  dob: string
  address: CreateCustomerRepDtoAddressDto
  reg: CreateCustomerRepDtoRegDto
}

export interface CreateCustomerRepDtoAddressDto {
  line1?: string | null
  line2?: string | null
  city?: string | null
  zip?: string | null
  country?: string | null
}

export interface CreateCustomerRepDtoRegDto {
  roleText: string
  statutory: boolean
  /** @format date */
  effectiveFrom: string
  /** @format date */
  effectiveTo: string
  intermediatePartyNames: string[]
}

export interface CreateCustomerRiskNoteDto {
  note: string
  /** @format uuid */
  customerId: string
  /** @format uuid */
  applicationId: string
}

export interface CreateFinancingRequestRepresentative {
  /**
   * Identifier of the representative
   * @format uuid
   */
  id: string
  /**
   * Email address
   * @format email
   */
  email?: string
  /** Phone number in international format E.123 */
  phone?: string
}

export type CreateOfferDto = UpdateOfferDto & {
  crowdfundingEnabled?: boolean
  state: OfferState
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country: IsoCountryCode
}

export interface CreateOfferLegacyDto {
  partnerCode: string
  merchantId: string
  crowdfundingEnabled: boolean
  products: CreateOfferLegacyDtoCreateOfferProduct[]
}

export interface CreateOfferLegacyDtoCreateOfferProduct {
  period: string
  /** @format int32 */
  riskGrade: number
  interestRate: number
  revenueForecast: number
  maxLoanAmount: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
}

export interface CreateSingleOfferDto {
  partnerCode: string
  /** @format uuid */
  customerId: string
  crowdfundingEnabled?: boolean
  products: CreateOfferLegacyDtoCreateOfferProduct[]
}

export interface CustomerDto {
  organization: Party
  /** @format date-time */
  effectiveFrom: string
  /** @format date-time */
  effectiveTo: string
  riskGrade: string
  /** @format date-time */
  riskGradeAt: string
  riskGradeCustom: string
  /** @format date-time */
  riskGradeCustomAt: string
  insightsReportFileRef: string
  onBehalfEnabled: boolean
  /** @format int64 */
  version: number
}

export interface CustomerListItemDto {
  /** @format uuid */
  id?: string
  name?: string
  regNum?: string
  country?: string
  address_city?: string
  address_zip?: string
  address_country?: string
  /** @format date-time */
  effectiveFrom?: string
  /** @format date-time */
  effectiveTo?: string
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  updatedAt?: string
}

export interface CustomerRepWithContactDto {
  /** @format uuid */
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  address: Address
  /** @format date-time */
  effectiveFrom: string
  /** @format date-time */
  effectiveTo: string
}

export interface CustomerRiskNoteDto {
  /** @format uuid */
  id: string
  /** @format uuid */
  customerId: string
  note: string
  /** @format int32 */
  noteVersion: number
  state: CustomerRiskNoteState
  /** @format date-time */
  deactivatedAt?: string | null
  /** @format uuid */
  deactivatedBy?: string | null
  deactivatedByUser?: string | null
  /** @format uuid */
  createdBy?: string | null
  createdByUser?: string | null
  /** @format uuid */
  updatedBy?: string | null
  updatedByUser?: string | null
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export enum CustomerRiskNoteState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export type CustomerScoring = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    customerId: string
    /** @format uuid */
    applicationId?: string
    state: CustomerScoringState
    creditcheckState: CustomerScoringCreditcheckState
    /** @format date-time */
    creditcheckAt?: string
    creditcheckErrorMessage?: string
    distraintcheckState: CustomerScoringDistraintcheckState
    /** @format date-time */
    distraintcheckAt?: string
    distraintcheckErrorMessage?: string
    scoreCard?: ScoreCard
  }

export enum CustomerScoringAction {
  START_NEW_SCORING = 'START_NEW_SCORING',
  RESTART_UNDERWRITING = 'RESTART_UNDERWRITING',
  RESTART_DISTRAINTCHECK = 'RESTART_DISTRAINTCHECK',
  SEND_FOR_MANUAL_APPROVAL = 'SEND_FOR_MANUAL_APPROVAL',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT'
}

export interface CustomerScoringActionRequestDto {
  action: CustomerScoringAction
}

export enum CustomerScoringCompletedResult {
  OFFER = 'OFFER',
  UNQUALIFIED = 'UNQUALIFIED'
}

export enum CustomerScoringCreditcheckState {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  MANUAL_CHECK = 'MANUAL_CHECK',
  SUCCESS = 'SUCCESS',
  KO = 'KO',
  ERROR = 'ERROR'
}

export enum CustomerScoringDistraintcheckState {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  MANUAL_CHECK = 'MANUAL_CHECK',
  SUCCESS = 'SUCCESS',
  KO = 'KO',
  ERROR = 'ERROR'
}

export enum CustomerScoringState {
  PENDING = 'PENDING',
  CREDITCHECK_SCHEDULED = 'CREDITCHECK_SCHEDULED',
  CREDITCHECK_PROCESSING = 'CREDITCHECK_PROCESSING',
  CREDITCHECK_MANUAL_CHECK = 'CREDITCHECK_MANUAL_CHECK',
  CREDITCHECK_ERROR = 'CREDITCHECK_ERROR',
  DISTRAINTCHECK_SCHEDULED = 'DISTRAINTCHECK_SCHEDULED',
  DISTRAINTCHECK_PROCESSING = 'DISTRAINTCHECK_PROCESSING',
  DISTRAINTCHECK_MANUAL_CHECK = 'DISTRAINTCHECK_MANUAL_CHECK',
  DISTRAINTCHECK_ERROR = 'DISTRAINTCHECK_ERROR',
  SUCCESS = 'SUCCESS',
  KO = 'KO',
  ERROR = 'ERROR',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export interface CustomerScoringTreeDto {
  riskProfileTagSeverityMap?: {
    NONE?: SetRiskProfileTag
    INFO?: SetRiskProfileTag
    MANUAL_CHECK?: SetRiskProfileTag
    KO?: SetRiskProfileTag
  }
  /** @format uuid */
  customerScoringId?: string
  customerScoringState?: CustomerScoringState
  /** @format uuid */
  applicationId?: string
  applicationState?: ApplicationState
  creditcheckState?: CustomerScoringCreditcheckState
  /** @format date-time */
  creditcheckAt?: string
  creditcheckErrorMessage?: string
  distraintcheckState?: CustomerScoringDistraintcheckState
  /** @format date-time */
  distraintcheckAt?: string
  distraintcheckErrorMessage?: string
  scoreCard?: ScoreCard
  parties?: PartyUnderwritingDto[]
  amlState?: ApplicationAmlState
  /** @format date-time */
  amlStateUpdatedAt?: string
  eligibleActions?: CustomerScoringAction[]
}

export interface CustomerWithContactsDto {
  /** @format uuid */
  id: string
  organization: Party
  reps: CustomerRepWithContactDto[]
}

export interface DateRange {
  /** @format date */
  from?: string
  /** @format date */
  till?: string
}

export interface DbTestResourceTestOutputRecord {
  dbRoundtripStat1?: TestResourceBenchmarkRecord
  dbRoundtripStat2?: TestResourceBenchmarkRecord
}

export interface DebtProviderDto {
  /** @format uuid */
  id: string
  name: string
  rate?: number | null
  /** @format date */
  effectiveFrom?: string | null
  /** @format date */
  effectiveTo?: string | null
}

export interface DetailedCategoryAmount {
  category?: string
  detailedCategory?: string
  amount?: number
}

export type Document = BaseIdEntity &
  BaseMetadataEntity & {
    type: string
    fileRef: string
    /** @format uuid */
    customerId: string
    /** @format uuid */
    applicationId?: string | null
  }

export interface DocumentDto {
  /** @format uuid */
  id: string
  type: string
  fileBase64: string
  fileName?: string
  contentType?: string
  /** @format uuid */
  applicationId: string
  /** @format uuid */
  partyId: string
  /** @format int64 */
  version: number
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export type ExternalData = BaseIdEntity &
  BaseMetadataEntity & {
    externalId?: string
    /** @format uuid */
    partyId?: string
    subjectId: string
    source: string
    type: string
    subjectName?: string
    requestUrl?: string
    requestHeaders?: string
    requestBody?: string
    /** @format int32 */
    responseStatus?: number
    responseHeaders?: string
    responseBody?: string
    responseJson?: string
    responseParsed?: any
    /** @format date-time */
    validTo?: string
  }

export interface ExternalUserDtoConnectedPartnerDto {
  name?: string
  code?: string
  merchantId?: string
  tenantId?: string
}

export interface ExternalUserDtoCustomerDto {
  /** @format uuid */
  id?: string
  name?: string
  country?: string
  regNum?: string
  partners?: ExternalUserDtoConnectedPartnerDto[]
  /** @format uuid */
  linkedRegRepId?: string
}

export interface ExternalUserDtoPartnerDto {
  /** @format uuid */
  id: string
  name: string
  code: string
  country: string
  regNum: string
  accountNumber: string
}

export interface FinancingInstallment {
  /**
   * Due date
   * @format date
   * @example "2020-12-21T00:00:00.000Z"
   */
  dueDate?: string
  /**
   * Paid date
   * @format date
   * @example "2020-12-21T00:00:00.000Z"
   */
  paidDate?: string
  /** Total installment amount */
  total?: number
  /** Principal */
  principal?: number
  /** Fee */
  fee?: number
  /** Prolongation or postponement fee */
  feeProlongationOrPostpone?: number
  /** Total paid amount */
  paidTotal?: number
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode
  /** Installment state */
  state?: InstallmentInstallmentState
}

export type FinancingParameter = BaseIdEntity &
  BaseMetadataEntity & {
    /** ISO 3166 alpha-2 country code enum, including metadata. */
    country?: IsoCountryCode
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency?: IsoCurrencyCode
    type?: FinancingParameterType
    /** @format date */
    validFrom?: string
    /** @format date */
    validTo?: string | null
    valueType?: FinancingParameterFinancingParameterValueType
    value?: number
  }

export enum FinancingParameterFinancingParameterValueType {
  PERCENTAGE = 'PERCENTAGE',
  FLAT_FEE = 'FLAT_FEE'
}

export enum FinancingParameterType {
  PENALTY_FEE = 'PENALTY_FEE',
  PROLONGATIONFEE1 = 'PROLONGATION_FEE_1',
  PROLONGATIONFEE2 = 'PROLONGATION_FEE_2'
}

export enum FinancingState {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  SENT_FOR_APPROVING = 'SENT_FOR_APPROVING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSED = 'DISBURSED',
  PAID = 'PAID',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION'
}

export enum FinancingState1 {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSED = 'DISBURSED',
  PAID = 'PAID',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION'
}

export enum FinancingState2 {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSED = 'DISBURSED',
  PAID = 'PAID',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION'
}

export enum FinancingState3 {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSED = 'DISBURSED',
  PAID = 'PAID',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION'
}

/**
 * Countries supported by FlowPay
 */
export enum FlowpayCountry {
  CZ = 'CZ',
  SK = 'SK',
  NL = 'NL'
}

/**
 * Insight report data All amounts are in CZK.
 */
export interface InsightReportDto {
  /** @format date */
  date?: string
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  accountDataAvailability?: DateRange
  psd2DataAvailability?: DateRange
  partnerDataAvailability?: any
  partnerCodes?: string[]
  partnerMonthlyData?: any
  psd2MonthlyData?: any
  accountMonthlyData?: any
  riskGradeSummary?: RiskGradeSummary
}

export interface InsightReportDtoAccountData {
  credit: number
  debit: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
}

export interface InsightReportDtoDataAvailability {
  monthRange?: DateRange
  /** @format date */
  firstTransactionDate?: string
  /** @format date */
  lastTransactionDate?: string
}

export interface InsightRevenueDto {
  partnerCode?: string
  /** @format date */
  date?: string
  /** @format int32 */
  revenue?: number
  /** @format int32 */
  orderCount?: number
  /** @format int32 */
  cancelledOrderCount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
}

export type Installment = BaseIdEntity &
  BaseMetadataEntity & {
    outstandingAmount?: number
    outstandingPrincipal?: number
    outstandingFeeReminder1?: number
    outstandingFeeReminder2?: number
    outstandingFee?: number
    outstandingPenaltyFee?: number
    /** @format uuid */
    loanId: string
    state: InstallmentInstallmentState
    /** @format date */
    dueDate: string
    type: InstallmentInstallmentType
    principal: number
    fee: number
    feeProlongation: number
    feePenalty: number
    feeReminder1: number
    feeReminder2: number
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency: IsoCurrencyCode
    total: number
    /** @format uuid */
    collectionId?: string
    /** @format date */
    paidDate?: string
    paidPrincipal?: number
    paidFee?: number
    paidFeeProlongation?: number
    paidFeePenalty?: number
    paidFeeReminder1?: number
    paidFeeReminder2?: number
    paidTotal?: number
    paymentNote?: string
    /** @format uuid */
    prolongationId?: string
    feePenaltyCalculated?: number
    /** @format date */
    feePenaltyCalculatedDate?: string
    /** @format int64 */
    daysOverdue?: number
  }

export enum InstallmentInstallmentState {
  PENDING = 'PENDING',
  PAID = 'PAID',
  PLANNED = 'PLANNED',
  OVERDUE = 'OVERDUE',
  CANCELED = 'CANCELED',
  RESCHEDULED = 'RESCHEDULED',
  PROLONGED = 'PROLONGED'
}

export enum InstallmentInstallmentType {
  STANDARD = 'STANDARD',
  PROLONGATION_FEE = 'PROLONGATION_FEE',
  AGREEMENT = 'AGREEMENT',
  DEFAULT = 'DEFAULT'
}

export interface InstallmentAdminDto {
  customerName?: string
  /** @format uuid */
  id?: string
  /** @format uuid */
  loanId?: string
  state?: InstallmentInstallmentState
  /** @format date */
  dueDate?: string
  type?: InstallmentInstallmentType
  principal?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  fee?: number
  feeProlongation?: number
  feePenalty?: number
  feeReminder1?: number
  feeReminder2?: number
  total?: number
  /** @format date */
  paidDate?: string
  paidPrincipal?: number
  paidFee?: number
  paidFeeProlongation?: number
  paidFeePenalty?: number
  paidFeeReminder1?: number
  paidFeeReminder2?: number
  paidTotal?: number
  paymentNote?: string
  /** @format uuid */
  prolongationId?: string
  feePenaltyCalculated?: number
  /** @format date */
  feePenaltyCalculatedDate?: string
  /** @format int64 */
  daysOverdue?: number
  /** @format int64 */
  version?: number
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  updatedAt?: string
}

export interface InternalUserCreateDto {
  username: string
  roles?: InternalUserRole[]
}

export enum InternalUserRole {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
  UNDERWRITER = 'UNDERWRITER',
  LEGAL = 'LEGAL',
  ACCOUNTING = 'ACCOUNTING'
}

export interface InternalUserSignupDto {
  username: string
}

/**
 * ISO 3166 alpha-2 country code enum, including metadata.
 */
export enum IsoCountryCode {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CV = 'CV',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  KY = 'KY',
  CF = 'CF',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CD = 'CD',
  CG = 'CG',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  SZ = 'SZ',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MK = 'MK',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW'
}

/**
 * ISO 4217 alpha-3 currency code enum, including metadata.
 */
export enum IsoCurrencyCode {
  ADP = 'ADP',
  AED = 'AED',
  AFA = 'AFA',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  ATS = 'ATS',
  AUD = 'AUD',
  AWG = 'AWG',
  AYM = 'AYM',
  AZM = 'AZM',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BEF = 'BEF',
  BGL = 'BGL',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOV = 'BOV',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYB = 'BYB',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CSD = 'CSD',
  CSK = 'CSK',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CYP = 'CYP',
  CZK = 'CZK',
  DEM = 'DEM',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EEK = 'EEK',
  EGP = 'EGP',
  ERN = 'ERN',
  ESP = 'ESP',
  ETB = 'ETB',
  EUR = 'EUR',
  FIM = 'FIM',
  FJD = 'FJD',
  FKP = 'FKP',
  FRF = 'FRF',
  GBP = 'GBP',
  GEL = 'GEL',
  GHC = 'GHC',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GRD = 'GRD',
  GTQ = 'GTQ',
  GWP = 'GWP',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  IDR = 'IDR',
  IEP = 'IEP',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  ITL = 'ITL',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LUF = 'LUF',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MGF = 'MGF',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MRU = 'MRU',
  MTL = 'MTL',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXV = 'MXV',
  MYR = 'MYR',
  MZM = 'MZM',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NLG = 'NLG',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PTE = 'PTE',
  PYG = 'PYG',
  QAR = 'QAR',
  ROL = 'ROL',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RUR = 'RUR',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDD = 'SDD',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SIT = 'SIT',
  SKK = 'SKK',
  SLE = 'SLE',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SRG = 'SRG',
  SSP = 'SSP',
  STD = 'STD',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMM = 'TMM',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TPE = 'TPE',
  TRL = 'TRL',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  USN = 'USN',
  USS = 'USS',
  UYI = 'UYI',
  UYU = 'UYU',
  UYW = 'UYW',
  UZS = 'UZS',
  VEB = 'VEB',
  VED = 'VED',
  VEF = 'VEF',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XBA = 'XBA',
  XBB = 'XBB',
  XBC = 'XBC',
  XBD = 'XBD',
  XCD = 'XCD',
  XDR = 'XDR',
  XFO = 'XFO',
  XFU = 'XFU',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  XSU = 'XSU',
  XTS = 'XTS',
  XUA = 'XUA',
  XXX = 'XXX',
  YER = 'YER',
  YUM = 'YUM',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZWD = 'ZWD',
  ZWL = 'ZWL',
  ZWN = 'ZWN',
  ZWR = 'ZWR'
}

export type KycVerification = BaseIdEntity &
  BaseMetadataEntity & {
    valid?: boolean
    /** @format uuid */
    personId?: string
    state?: KycVerificationState
    personRef?: string
    verificationRef?: string
    firstNameEn?: string
    lastNameEn?: string
    /** @format date */
    dob?: string
    birthNumber?: string
    idCard?: string
    country?: string
    /** @format date-time */
    reviewedAt?: string
    /** @format uuid */
    applicationId?: string
    /** @format uuid */
    partnerId?: string
    /** @format date */
    validTo?: string
  }

export enum KycVerificationReviewedState {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export enum KycVerificationState {
  REQUESTED = 'REQUESTED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID'
}

export type Lead = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    customerId?: string
    partnerCode?: string
    merchantId?: string
    tenantId?: string
    state: LeadState
    salesState?: LeadSalesState
    /** @format uuid */
    offerId?: string
    /**
     * Two-letter country code as defined by ISO 3166-1 alpha-2 or value '_U' representing a user person
     * @example CZ
     */
    country?: string
    regNum?: string
    /** @format email */
    email?: string
    phone?: string
    notes?: string
    referrer?: string
    utmSource?: string
    utmCampaign?: string
    utmMedium?: string
    utmContent?: string
    utmTerm?: string
    utmId?: string
    product?: ProductParams4
    /** @format uuid */
    userId?: string
  }

export interface LeadDto {
  /** @format uuid */
  id: string
  partnerCode: string
  merchantId: string
  state: LeadState
  salesState?: LeadSalesState
  /** @format uuid */
  offerId: string
  product?: LeadProductDto
  existingCustomer?: boolean
}

export interface LeadProductDto {
  type: ProductType
  amount: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
  amountComplete: number
  feeMonthly: number
  feeProlongation: number
  /** @format int32 */
  postponedPeriods: number
  prolonged: boolean
  crowdfunded: boolean
}

export enum LeadSalesState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DEAD = 'DEAD',
  HOT_LEAD = 'HOT_LEAD',
  NOT_RELEVANT = 'NOT_RELEVANT',
  NOT_INTERESTED = 'NOT_INTERESTED',
  NO_ANSWER = 'NO_ANSWER',
  SUCCESSFUL = 'SUCCESSFUL',
  LACK_OF_INFO = 'LACK_OF_INFO',
  IN_FUTURE = 'IN_FUTURE'
}

export enum LeadState {
  LEAD = 'LEAD',
  PROSPECT = 'PROSPECT',
  CONTACT = 'CONTACT',
  SIGNUP = 'SIGNUP',
  CONNECTED = 'CONNECTED',
  UNQUALIFIED = 'UNQUALIFIED',
  ACTIVE_PRODUCT = 'ACTIVE_PRODUCT',
  SINGLE_OFFER = 'SINGLE_OFFER',
  OPPORTUNITY = 'OPPORTUNITY',
  INTEREST = 'INTEREST',
  INDICATIVE = 'INDICATIVE',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  SUCCESSFUL = 'SUCCESSFUL',
  NO_OFFER = 'NO_OFFER',
  OFFER_PROCESSING_ERROR = 'OFFER_PROCESSING_ERROR',
  MERCHANT_DATA_ERROR = 'MERCHANT_DATA_ERROR'
}

export interface LeadUpdateDto {
  /** @format uuid */
  id?: string
  salesState?: LeadSalesState
  /** @format uuid */
  userId?: string | null
  /** @format int64 */
  version?: number
}

export type Loan = BaseIdEntity &
  BaseMetadataEntity & {
    active?: boolean
    /** @format uuid */
    customerId?: string
    customerName?: string
    /** @format uuid */
    partnerId?: string
    /** @format uuid */
    applicationId?: string
    /** @format uuid */
    commissionSchemaId?: string
    feePenaltyAutoUpdate?: boolean
    notifDisabled?: boolean
    internalRef?: string
    externalRef?: string
    state?: LoanLoanState
    product?: ProductType
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency?: IsoCurrencyCode
    principal?: number
    ratePerc?: number
    feeMonth?: number
    feeTotal?: number
    feeProlongation?: number
    repaymentTotal?: number
    paidPerc?: number
    /** @format int32 */
    postponedPeriods?: number
    prolonged?: boolean
    crowdfunded?: boolean
    iban?: string
    /** @format date-time */
    disbursedAt?: string
    /** @format date-time */
    paidAt?: string
    /** @format date */
    collectionAt?: string
    contractUrl?: string
    installments?: Installment[]
    /** @format uuid */
    debtProviderId?: string
  }

export enum LoanLoanState {
  CREATED = 'CREATED',
  DISBURSED = 'DISBURSED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION',
  PAID = 'PAID',
  FAILED = 'FAILED'
}

export interface LoanDto {
  /** @format uuid */
  id?: string
  state?: LoanLoanState
  /** @format uuid */
  customerId?: string
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode
  principal?: number
  /** @format date */
  from?: string
  productType?: ProductType
  /** @format int32 */
  months?: number
  /** @format int32 */
  postponedPeriods?: number
  prolonged?: boolean
  crowdfunded?: boolean
  totalRepayment?: number
  totalPaid?: number
  outstandingAmount?: number
  paidPerc?: number
  instalments?: LoanDtoInstallmentDto[]
  paymentInstruction?: LoanDtoPaymentInstructionDto
  prolongationEligible?: boolean
  prolongationFee?: number
  /** @format int32 */
  maxProlongationPeriodMonths?: number
  contractUrl?: string
  /** @format uuid */
  termsId?: string
  /** @format uuid */
  contractDocId?: string
  /** @format uuid */
  debtProviderId?: string
}

export interface LoanDtoInstallmentDto {
  /** @format date */
  dueDate?: string
  /** @format date */
  paidDate?: string
  total?: number
  principal?: number
  fee?: number
  paidTotal?: number
  state?: InstallmentInstallmentState
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode
}

export interface LoanDtoPaymentInstructionDto {
  bban: string
  iban: string
  qrCodeString: string
  reference: string
  amount: number
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode
  /** @format date */
  dueDate: string
}

export interface LoanInvolvementDto {
  /** @format uuid */
  id?: string
  customerName?: string
  involvedRole?: string
  applicationState?: ApplicationState
  loanState?: LoanLoanState
  productType?: ProductType
  amount?: number
  ratePerc?: number
  /** @format date-time */
  disbursedAt?: string
  outstandingPrincipal?: number
  /** @format int32 */
  overdueInstallmentsCount?: number
  /** @format int32 */
  maxDaysOverdue?: number
  /** @format int32 */
  riskGrade?: number
  /** @format date */
  lastInstallment?: string
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
}

export type LoanServicingAction = BaseIdEntity &
  BaseMetadataEntity & {
    type: ServicingActionType
    note?: string
  }

export interface LoansOverviewDtoPreviousLoanDto {
  /** @format uuid */
  id?: string
  state?: LoanLoanState
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  /** @format date */
  from?: string
  /** @format date */
  to?: string
}

export interface MerchantBankAccountBalance {
  /** Account balance currency as defined by ISO 4217 */
  currency: IsoCurrencyCode
  /** Account balance amount */
  amount: number
  /** Account balance type */
  type?: string
  /**
   * Account balance reference date
   * @format date
   * @example "2020-12-21T00:00:00.000Z"
   */
  referenceDate: string
}

export interface ModifyOwnerDto {
  partyType: PartyType
  person?: ModifyOwnerDtoModifyOwnerPersonDto
  organization?: ModifyOwnerDtoModifyOwnerOrganizationDto
}

export interface ModifyOwnerDtoModifyOwnerOrganizationDto {
  name: string
  regNum: string
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  countryOfRegistration: IsoCountryCode
  fop?: boolean
  legalForm: string
  court?: string
  sharePerc?: number
  /** @format date */
  effectiveFrom: string
  /** @format date */
  effectiveTo?: string
  selfDeclaredUbo?: boolean
  pep?: boolean
  street: string
  city: string
  zip: string
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country: IsoCountryCode
}

export interface ModifyOwnerDtoModifyOwnerPersonDto {
  firstName: string
  lastName: string
  /** @format date */
  dateOfBirth: string
  sharePerc?: number
  /** @format date */
  effectiveFrom: string
  /** @format date */
  effectiveTo?: string
  selfDeclaredUbo?: boolean
  pep?: boolean
  street: string
  city: string
  zip: string
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country: IsoCountryCode
  nationality?: string
}

export interface MonthlyAmounts {
  /** @format date */
  date?: string
  credit?: number
  debit?: number
}

export type Offer = BaseIdEntity &
  BaseMetadataEntity & {
    partnerCode: string
    merchantId: string
    state: OfferState
    type?: OfferType
    crowdfundingEnabled: boolean
    products?: OfferProduct[]
    published: boolean
    /** ISO 3166 alpha-2 country code enum, including metadata. */
    country: IsoCountryCode
  }

export interface OfferAdminDto {
  /** @format uuid */
  id: string
  partnerCode: string
  merchantId: string
  state: OfferState
  type?: OfferType
  crowdfundingEnabled: boolean
  published?: boolean
  /** @format int64 */
  version?: number
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country: IsoCountryCode
}

export interface OfferAdminListDto {
  /** @format uuid */
  id: string
  partnerCode: string
  merchantId: string
  state: OfferState
  type?: OfferType
  crowdfundingEnabled: boolean
  /** @format uuid */
  leadId?: string
  /** @format uuid */
  applicationId?: string
  customerName?: string
  customerId?: string
  published?: boolean
  /** @format int64 */
  version?: number
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country?: IsoCountryCode
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  updatedAt?: string
}

export interface OfferDto {
  /** @format uuid */
  id: string
  partnerCode: string
  merchantId: string
  state: OfferState
  crowdfundingEnabled: boolean
  products: OfferDtoOfferProductDto[]
  customerWithActiveProduct: boolean
  published: boolean
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country: IsoCountryCode
}

export interface OfferDtoOfferProductDto {
  product: ProductType
  ratePerc: number
  minAmount: number
  maxAmount: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
}

export type OfferProduct = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    offerId: string
    product: ProductType
    ratePerc: number
    maxAmount: number
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency: IsoCurrencyCode
    /** @format int32 */
    riskGrade: number
    revenueForecast: number
  }

export interface OfferProductDto {
  /** @format uuid */
  id: string
  product: ProductType
  ratePerc: number
  maxAmount: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
  /** @format int32 */
  riskGrade: number
  revenueForecast: number
  /** @format int64 */
  version?: number
}

export interface OfferPublishCriteriaResponseDto {
  criteriaMap: {
    OFFER_IN_NEW_STATE?: boolean
    HAS_NO_PENDING_FINANCING?: boolean
    NEWEST_OFFER_MAX_30_DAYS_OLD?: boolean
    CLIENT_NOT_BLACKLISTED?: boolean
    HAS_NO_NOT_APPROVED_LOAN_PAST_3_MONTHS?: boolean
    HAS_NO_COLLECTION_FINANCING?: boolean
    HAS_NO_OVERDUE_FINANCING?: boolean
    OLDEST_FINANCING_PAID_OR_3_INSTALLMENTS_PAID?: boolean
    HAS_NO_ACTIVE_LOAN_WITH_RESTRUCTURED_ACTIVITY?: boolean
  }
  creditCheckState: CustomerScoringCreditcheckState
}

export interface OfferRiskEvaluationsOutputDto {
  businessData: RiskEvaluation
  openBankingData?: RiskEvaluation
}

export enum OfferState {
  NEW = 'NEW',
  SENT = 'SENT',
  LEAD = 'LEAD',
  SUCCESSFUL = 'SUCCESSFUL',
  CANCELLED = 'CANCELLED'
}

export enum OfferType {
  BULK = 'BULK',
  SINGLE = 'SINGLE',
  INDIVIDUAL = 'INDIVIDUAL',
  AGGREGATED = 'AGGREGATED'
}

export type OfferWithProductsAdminDto = OfferAdminDto & {
  products?: OfferProduct[]
}

export interface OpenBankingCategoriesDto {
  categories?: string[]
  subCategories?: string[]
  detailedCategories?: string[]
}

export interface OwnerDto {
  /** @format uuid */
  id?: string
  party?: Party
  sharePerc?: number
  ubo?: boolean
  selfDeclaredUbo?: boolean
  /** @format date-time */
  effectiveFrom?: string
  /** @format date-time */
  effectiveTo?: string
  /** @format int64 */
  version?: number
}

export type Pageable = Sort & {
  /** @format int32 */
  number?: number
  /** @format int32 */
  size: number
  sort: Sort
}

export enum PartnerPartnerCategory {
  POS = 'POS',
  ECOMMERCE = 'ECOMMERCE',
  OTHER = 'OTHER',
  GASTRO = 'GASTRO',
  PPC = 'PPC'
}

export enum PartnerPartnerType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export interface PartnerConnectionDto {
  /** @format uuid */
  id?: string
  /** @format uuid */
  customerId?: string
  partnerId?: string
  partnerCode?: string
  merchantId?: string
  tenantId?: string
  state?: PartnerConnectionState
  productCategory?: string
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  connectedAt?: string
  /** @format date-time */
  disconnectedAt?: string
}

export enum PartnerConnectionState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface PartnerDto {
  /** @format uuid */
  id?: string
  code?: string
  partyName?: string
  partyPhone?: string
  partyEmail?: string
  category?: PartnerPartnerCategory
  containerName?: string
  insightsRevenuesEnabled?: boolean
  /** @format date-time */
  effectiveFrom?: string
  /** @format date-time */
  effectiveTo?: string
  /** @format date-time */
  updatedAt?: string
}

export interface PartnerLoansOverviewDtoMonthlyAmountDto {
  /** @format date */
  date: string
  amount: number
}

export interface PartnerOfferOfferProduct {
  /** Product type */
  product: ProductType
  /** Minimum financing amount for the given product in local currency */
  minAmount: number
  /** Maximum financing amount for the given product in local currency */
  maxAmount: number
  /** Currency as defined by ISO 4217 (https://en.wikipedia.org/wiki/ISO_4217) */
  currency: IsoCurrencyCode
  /** Fee rate percentage [%] */
  ratePerc: number
  balloon?: boolean
  /**
   * Total number of installments
   * @format int32
   */
  installmentCount: number
  /** Indicating whether prolongation is available for this product */
  prolongationEnabled: boolean
  /** Indicating whether postponement is available for this product */
  postponeEnabled: boolean
  /**
   * Maximum number of postponed installments
   * @format int32
   */
  maxPostponeLength: number
  /**
   * Maximum number of installments for prolongation
   * @format int32
   */
  maxProlongationLength: number
  interestRateMultiplier: number
  postponeFeeMultiplier: number
  prolongationFeeMultiplier: number
}

export interface PartnerOffersOverviewDtoMonthlyOffersDto {
  /** @format date */
  date: string
  /** @format int32 */
  count: number
}

export interface PartnerProductParams {
  /** Financing product type */
  type: ProductType
  /** Financing amount */
  amount: number
  /** Fee rate percentage [%] */
  ratePerc: number
  /** Currency as defined by ISO 4217 */
  currency: IsoCurrencyCode
  /**
   * Postponed months
   * @format int32
   */
  postponedPeriods?: number | null
  /** Boolean flag for financing prolongation */
  prolonged?: boolean | null
}

export interface PartnerUpdateDto {
  /** @format uuid */
  id: string
  type: PartnerPartnerType
  category: PartnerPartnerCategory
  code: string
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  commissionCurrency: IsoCurrencyCode
  /** @format date-time */
  effectiveFrom: string
  /** @format date-time */
  effectiveTo: string
  insightsRevenuesEnabled: boolean
  ibanCZK: string
  ibanEUR: string
  /** @format int64 */
  version: number
}

export type Party = BaseIdEntity &
  BaseMetadataEntity & {
    orgNameOrRegNum?: string
    fullName?: string
    organization?: boolean
    person?: boolean
    fopOrganization?: boolean
    userParty?: boolean
    partyName?: string
    externalId?: string
    type: PartyType
    /**
     * Two-letter country code as defined by ISO 3166-1 alpha-2 or value '_U' representing a user person
     * @example CZ
     */
    country: string
    nationality?: string
    /** @format email */
    email?: string
    phone?: string
    /** @format date-time */
    phoneVerifiedAt?: string
    name?: string
    legalForm?: string
    /** @format date */
    regSince?: string
    regNum?: string
    regNum2?: string
    regCourt?: string
    taxNum?: string
    vatNum?: string
    /** @format date */
    vatSince?: string
    web?: string
    iban?: string
    firstName?: string
    lastName?: string
    /** @format date */
    dob?: string
    birthNumber?: string
    pep?: boolean
    fop?: boolean
    consentGiven?: boolean
    /** @format date-time */
    consentChangedAt?: string
    segment?: string
    partnerCode?: string
    address?: Address
    sourceType: PartySourceType
    sourcePriority: PartySourcePriority
    /** @format date-time */
    registerIdentityAt?: string
    /** @format date-time */
    registerDataAt?: string
    activity?: string
    activityEn?: string
  }

export interface PartyAdminDto {
  orgNameOrRegNum?: string
  fullName?: string
  /** @format uuid */
  id?: string
  type?: PartyType
  /** @format email */
  email?: string
  phone?: string
  name?: string
  legalForm?: string
  /** @format date */
  regSince?: string
  /**
   * Two-letter country code as defined by ISO 3166-1 alpha-2 or value '_U' representing a user person
   * @example CZ
   */
  country?: string
  partnerCode?: string
  regNum?: string
  regCourt?: string
  taxNum?: string
  vatNum?: string
  /** @format date */
  vatSince?: string
  firstName?: string
  lastName?: string
  /** @format date */
  dob?: string
  birthNumber?: string
  pep?: boolean
  address?: Address
  isCustomer?: boolean
  /** @format date-time */
  updatedAt?: string
  /** @format date-time */
  createdAt?: string
  /** @format int64 */
  version?: number
}

export interface PartyOrganizationId {
  /** @format uuid */
  partyId: string
  /** @format uuid */
  organizationId: string
}

export enum PartySourcePriority {
  UNDEFINED = 'UNDEFINED',
  REGISTER_ALL = 'REGISTER_ALL',
  REGISTER_NON_BLANKS = 'REGISTER_NON_BLANKS',
  LOCAL_ALL = 'LOCAL_ALL'
}

export enum PartySourceType {
  UNDEFINED = 'UNDEFINED',
  REGISTER = 'REGISTER',
  OPERATOR = 'OPERATOR',
  USER = 'USER'
}

export enum PartyType {
  PERSON = 'PERSON',
  ORGANIZATION = 'ORGANIZATION'
}

export interface PartyUnderwritingDto {
  /** @format uuid */
  partyId?: string
  regNum?: string
  country?: string
  partyType?: PartyType
  fop?: boolean
  partyLabel?: string
  /** @format int32 */
  level?: number
  roleLabel?: string
  roleContext?: string
  tags?: RiskProfileTag[]
  severity?: RiskProfileTagSeverity
}

export interface PaymentInstruction {
  /**
   * Bank account number for installment repayment
   * @example CZ6508000000192000145399
   */
  iban?: string
  /**
   * Bank account number for installment repayment
   * @example 19-2000145399/0800
   */
  bban?: string
  /** Payment reference (symbol) */
  reference?: string
  /** Payment amount */
  amount?: number
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode
  /**
   * Installment due date
   * @format date
   * @example "2020-12-21T00:00:00.000Z"
   */
  dueDate?: string
}

export type PrincipalInCirculationAggByPartnerAndMonth = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format date */
    month?: string
    partnerCode?: string
    /** @format uuid */
    partnerId?: string
    amount?: number
  }

export interface ProductParams {
  type?: ProductType
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  ratePerc?: number
  /** @format int32 */
  postponedPeriods?: number
  prolonged?: boolean | null
  crowdfunded?: boolean | null
  /** @format int32 */
  riskGrade?: number
}

export interface ProductParams1 {
  type?: ProductType
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  /** @format int32 */
  postponedPeriods?: number
  prolonged?: boolean | null
  crowdfunded?: boolean | null
}

export interface ProductParams2 {
  type?: ProductType
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  /** @format int32 */
  postponedPeriods?: number
  prolonged?: boolean | null
  crowdfunded?: boolean | null
}

export interface ProductParams3 {
  type?: ProductType
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  /** @format int32 */
  postponedPeriods?: number
  prolonged?: boolean | null
  crowdfunded?: boolean | null
}

export interface ProductParams4 {
  type?: ProductType
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  /** @format int32 */
  postponedPeriods?: number
  prolonged?: boolean | null
  crowdfunded?: boolean | null
}

export enum ProductType {
  M1 = 'M1',
  M3 = 'M3',
  M3R = 'M3R',
  M6 = 'M6',
  M12 = 'M12'
}

export type PsdAccount = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    customerId?: string
    externalId?: string
    bic?: string
    /** @format uuid */
    bankId?: string
    iban?: string
    name?: string
    ownerName?: string
    displayName?: string
    product?: string
    cashAccountType?: string
    institutionId?: string
    providerStatus?: string
    /** @format date-time */
    accountCreated?: string
    /** @format date-time */
    accountLastAccessed?: string
    /** @format date-time */
    firstCompletedReportAt?: string
    /** @format date-time */
    lastCompletedReportAt?: string
    /** @format uuid */
    lastCompletedReportConnectionId?: string
    forceReplaceTransactions?: boolean
    accountCurrencies?: {
      ADP?: PsdAccountCurrency
      AED?: PsdAccountCurrency
      AFA?: PsdAccountCurrency
      AFN?: PsdAccountCurrency
      ALL?: PsdAccountCurrency
      AMD?: PsdAccountCurrency
      ANG?: PsdAccountCurrency
      AOA?: PsdAccountCurrency
      ARS?: PsdAccountCurrency
      ATS?: PsdAccountCurrency
      AUD?: PsdAccountCurrency
      AWG?: PsdAccountCurrency
      AYM?: PsdAccountCurrency
      AZM?: PsdAccountCurrency
      AZN?: PsdAccountCurrency
      BAM?: PsdAccountCurrency
      BBD?: PsdAccountCurrency
      BDT?: PsdAccountCurrency
      BEF?: PsdAccountCurrency
      BGL?: PsdAccountCurrency
      BGN?: PsdAccountCurrency
      BHD?: PsdAccountCurrency
      BIF?: PsdAccountCurrency
      BMD?: PsdAccountCurrency
      BND?: PsdAccountCurrency
      BOB?: PsdAccountCurrency
      BOV?: PsdAccountCurrency
      BRL?: PsdAccountCurrency
      BSD?: PsdAccountCurrency
      BTN?: PsdAccountCurrency
      BWP?: PsdAccountCurrency
      BYB?: PsdAccountCurrency
      BYN?: PsdAccountCurrency
      BYR?: PsdAccountCurrency
      BZD?: PsdAccountCurrency
      CAD?: PsdAccountCurrency
      CDF?: PsdAccountCurrency
      CLF?: PsdAccountCurrency
      CLP?: PsdAccountCurrency
      CNY?: PsdAccountCurrency
      COP?: PsdAccountCurrency
      COU?: PsdAccountCurrency
      CRC?: PsdAccountCurrency
      CSD?: PsdAccountCurrency
      CSK?: PsdAccountCurrency
      CUC?: PsdAccountCurrency
      CUP?: PsdAccountCurrency
      CVE?: PsdAccountCurrency
      CYP?: PsdAccountCurrency
      CZK?: PsdAccountCurrency
      DEM?: PsdAccountCurrency
      DJF?: PsdAccountCurrency
      DKK?: PsdAccountCurrency
      DOP?: PsdAccountCurrency
      DZD?: PsdAccountCurrency
      EEK?: PsdAccountCurrency
      EGP?: PsdAccountCurrency
      ERN?: PsdAccountCurrency
      ESP?: PsdAccountCurrency
      ETB?: PsdAccountCurrency
      EUR?: PsdAccountCurrency
      FIM?: PsdAccountCurrency
      FJD?: PsdAccountCurrency
      FKP?: PsdAccountCurrency
      FRF?: PsdAccountCurrency
      GBP?: PsdAccountCurrency
      GEL?: PsdAccountCurrency
      GHC?: PsdAccountCurrency
      GHS?: PsdAccountCurrency
      GIP?: PsdAccountCurrency
      GMD?: PsdAccountCurrency
      GNF?: PsdAccountCurrency
      GRD?: PsdAccountCurrency
      GTQ?: PsdAccountCurrency
      GWP?: PsdAccountCurrency
      GYD?: PsdAccountCurrency
      HKD?: PsdAccountCurrency
      HNL?: PsdAccountCurrency
      HRK?: PsdAccountCurrency
      HTG?: PsdAccountCurrency
      HUF?: PsdAccountCurrency
      CHE?: PsdAccountCurrency
      CHF?: PsdAccountCurrency
      CHW?: PsdAccountCurrency
      IDR?: PsdAccountCurrency
      IEP?: PsdAccountCurrency
      ILS?: PsdAccountCurrency
      INR?: PsdAccountCurrency
      IQD?: PsdAccountCurrency
      IRR?: PsdAccountCurrency
      ISK?: PsdAccountCurrency
      ITL?: PsdAccountCurrency
      JMD?: PsdAccountCurrency
      JOD?: PsdAccountCurrency
      JPY?: PsdAccountCurrency
      KES?: PsdAccountCurrency
      KGS?: PsdAccountCurrency
      KHR?: PsdAccountCurrency
      KMF?: PsdAccountCurrency
      KPW?: PsdAccountCurrency
      KRW?: PsdAccountCurrency
      KWD?: PsdAccountCurrency
      KYD?: PsdAccountCurrency
      KZT?: PsdAccountCurrency
      LAK?: PsdAccountCurrency
      LBP?: PsdAccountCurrency
      LKR?: PsdAccountCurrency
      LRD?: PsdAccountCurrency
      LSL?: PsdAccountCurrency
      LTL?: PsdAccountCurrency
      LUF?: PsdAccountCurrency
      LVL?: PsdAccountCurrency
      LYD?: PsdAccountCurrency
      MAD?: PsdAccountCurrency
      MDL?: PsdAccountCurrency
      MGA?: PsdAccountCurrency
      MGF?: PsdAccountCurrency
      MKD?: PsdAccountCurrency
      MMK?: PsdAccountCurrency
      MNT?: PsdAccountCurrency
      MOP?: PsdAccountCurrency
      MRO?: PsdAccountCurrency
      MRU?: PsdAccountCurrency
      MTL?: PsdAccountCurrency
      MUR?: PsdAccountCurrency
      MVR?: PsdAccountCurrency
      MWK?: PsdAccountCurrency
      MXN?: PsdAccountCurrency
      MXV?: PsdAccountCurrency
      MYR?: PsdAccountCurrency
      MZM?: PsdAccountCurrency
      MZN?: PsdAccountCurrency
      NAD?: PsdAccountCurrency
      NGN?: PsdAccountCurrency
      NIO?: PsdAccountCurrency
      NLG?: PsdAccountCurrency
      NOK?: PsdAccountCurrency
      NPR?: PsdAccountCurrency
      NZD?: PsdAccountCurrency
      OMR?: PsdAccountCurrency
      PAB?: PsdAccountCurrency
      PEN?: PsdAccountCurrency
      PGK?: PsdAccountCurrency
      PHP?: PsdAccountCurrency
      PKR?: PsdAccountCurrency
      PLN?: PsdAccountCurrency
      PTE?: PsdAccountCurrency
      PYG?: PsdAccountCurrency
      QAR?: PsdAccountCurrency
      ROL?: PsdAccountCurrency
      RON?: PsdAccountCurrency
      RSD?: PsdAccountCurrency
      RUB?: PsdAccountCurrency
      RUR?: PsdAccountCurrency
      RWF?: PsdAccountCurrency
      SAR?: PsdAccountCurrency
      SBD?: PsdAccountCurrency
      SCR?: PsdAccountCurrency
      SDD?: PsdAccountCurrency
      SDG?: PsdAccountCurrency
      SEK?: PsdAccountCurrency
      SGD?: PsdAccountCurrency
      SHP?: PsdAccountCurrency
      SIT?: PsdAccountCurrency
      SKK?: PsdAccountCurrency
      SLE?: PsdAccountCurrency
      SLL?: PsdAccountCurrency
      SOS?: PsdAccountCurrency
      SRD?: PsdAccountCurrency
      SRG?: PsdAccountCurrency
      SSP?: PsdAccountCurrency
      STD?: PsdAccountCurrency
      STN?: PsdAccountCurrency
      SVC?: PsdAccountCurrency
      SYP?: PsdAccountCurrency
      SZL?: PsdAccountCurrency
      THB?: PsdAccountCurrency
      TJS?: PsdAccountCurrency
      TMM?: PsdAccountCurrency
      TMT?: PsdAccountCurrency
      TND?: PsdAccountCurrency
      TOP?: PsdAccountCurrency
      TPE?: PsdAccountCurrency
      TRL?: PsdAccountCurrency
      TRY?: PsdAccountCurrency
      TTD?: PsdAccountCurrency
      TWD?: PsdAccountCurrency
      TZS?: PsdAccountCurrency
      UAH?: PsdAccountCurrency
      UGX?: PsdAccountCurrency
      USD?: PsdAccountCurrency
      USN?: PsdAccountCurrency
      USS?: PsdAccountCurrency
      UYI?: PsdAccountCurrency
      UYU?: PsdAccountCurrency
      UYW?: PsdAccountCurrency
      UZS?: PsdAccountCurrency
      VEB?: PsdAccountCurrency
      VED?: PsdAccountCurrency
      VEF?: PsdAccountCurrency
      VES?: PsdAccountCurrency
      VND?: PsdAccountCurrency
      VUV?: PsdAccountCurrency
      WST?: PsdAccountCurrency
      XAF?: PsdAccountCurrency
      XAG?: PsdAccountCurrency
      XAU?: PsdAccountCurrency
      XBA?: PsdAccountCurrency
      XBB?: PsdAccountCurrency
      XBC?: PsdAccountCurrency
      XBD?: PsdAccountCurrency
      XCD?: PsdAccountCurrency
      XDR?: PsdAccountCurrency
      XFO?: PsdAccountCurrency
      XFU?: PsdAccountCurrency
      XOF?: PsdAccountCurrency
      XPD?: PsdAccountCurrency
      XPF?: PsdAccountCurrency
      XPT?: PsdAccountCurrency
      XSU?: PsdAccountCurrency
      XTS?: PsdAccountCurrency
      XUA?: PsdAccountCurrency
      XXX?: PsdAccountCurrency
      YER?: PsdAccountCurrency
      YUM?: PsdAccountCurrency
      ZAR?: PsdAccountCurrency
      ZMK?: PsdAccountCurrency
      ZMW?: PsdAccountCurrency
      ZWD?: PsdAccountCurrency
      ZWL?: PsdAccountCurrency
      ZWN?: PsdAccountCurrency
      ZWR?: PsdAccountCurrency
    }
  }

export type PsdAccountCurrency = BaseMetadataEntity & {
  /** @format uuid */
  accountId: string
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
  balance?: number
  balanceType?: string
  /** @format date */
  balanceReferenceDate?: string
  /** @format date-time */
  balanceUpdatedAt?: string
  periodBins?: any
  transactions?: PsdTransaction[]
}

export interface PsdAccountPreview {
  /** @format uuid */
  id?: string
  iban?: string
  ownerName?: string
}

export type PsdConnection = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    customerId?: string
    /** @format uuid */
    applicationId?: string
    state?: PsdConnectionState
    provider?: PsdConnectionProvider
    customerRef?: string
    connectionRef?: string
    holderInfoSupported?: boolean
    /** @format date-time */
    firstCompletedReportAt?: string
    /** @format date-time */
    lastCompletedReportAt?: string
    /** @format date-time */
    lastErrorAt?: string
    /** @format int32 */
    errorCount?: number
    errorMessage?: string
    /** @format date-time */
    deferredTo?: string
    iban?: string
    /** @format uuid */
    bankId?: string
    /** @format date */
    consentValidTo?: string
    /** @format date-time */
    reminder1At?: string
    /** @format date-time */
    reminder2At?: string
    /** @format date-time */
    reportRequestedAt?: string
    forceReplaceTransactions?: boolean
  }

export enum PsdConnectionProvider {
  NORDIGEN = 'NORDIGEN',
  SHOPTET = 'SHOPTET'
}

export enum PsdConnectionState {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FAILED = 'FAILED'
}

export interface PsdPeriodBin {
  saldo?: number
  /** @format uuid */
  accountId: string
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
  periodType: PsdPeriodType
  /** @format int32 */
  periodId: number
  /** @format int32 */
  creditCount?: number
  creditSum?: number
  /** @format int32 */
  debitCount?: number
  debitSum?: number
}

export enum PsdPeriodType {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export interface PsdReportDto {
  /** @format uuid */
  customerId?: string
  /** @format uuid */
  applicationId?: string
  accounts?: PsdAccount[]
  periodType?: PsdPeriodType
  periodIds?: any
}

export type PsdTransaction = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    connectionId?: string
    /** @format uuid */
    accountId: string
    accountExternalId?: string
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency: IsoCurrencyCode
    direction: PsdTransactionDirection
    /** @format date */
    valueDate?: string
    /** @format date */
    bookingDate: string
    amount?: number
    counterAccount?: string
    counterName?: string
    remittanceInfo?: string
    vs?: string
    ss?: string
    ks?: string
    externalId?: string
    externalId2?: string
    externalId3?: string
    externalData?: string
    /** @format int32 */
    rank?: number
    dirty?: boolean
  }

export enum PsdTransactionDirection {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export interface RecalculateParamsInputDto {
  productType: ProductType
  /** @format int32 */
  riskGrade: number
}

export interface RecalculateParamsOutputDto {
  hasProductInOffer?: boolean
  /** @format int32 */
  riskGrade?: number
  maxAmount?: number
  ratePerc?: number
}

export interface RecurrentTransactions {
  counterName?: string
  counterAccount?: string
  amount?: number
  /** @format int64 */
  txCount?: number
  txAmountSum?: number
  remittanceInfo?: string
}

export interface RegisterInfoOrganization {
  country?: string
  name?: string
  regNum?: string
  taxNum?: string
  vatNum?: string
  address?: Address
  legalForm?: string
  /** @format date */
  regSince?: string
  regCourt?: string
  fop?: boolean
}

export enum RegisterInfoState {
  VALID_REGNUM = 'VALID_REGNUM',
  EMPTY_COUNTRY = 'EMPTY_COUNTRY',
  UNSUPPORTED_COUNTRY = 'UNSUPPORTED_COUNTRY',
  EMPTY_REGNUM = 'EMPTY_REGNUM',
  INVALID_REGNUM_FORMAT = 'INVALID_REGNUM_FORMAT',
  NONEXISTING_REGNUM = 'NONEXISTING_REGNUM',
  INACTIVE_COMPANY = 'INACTIVE_COMPANY',
  DUPLICIT_REGNUM = 'DUPLICIT_REGNUM',
  REGISTER_LOOKUP_FAILED = 'REGISTER_LOOKUP_FAILED'
}

export enum RelPartyUserPartyState {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED'
}

export type RelTxnCollectionFee = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    collectionFeeId?: string
    /** @format uuid */
    bankTransactionId?: string
    internalComment?: string
    amount?: number
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency?: IsoCurrencyCode
    /** @format date */
    effectiveDate?: string
  }

/**
 * Document : services.main - RelTxnInstallment.java Created on : 27.8.2022, 17:44
 */
export type RelTxnInstallment = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    installmentId?: string
    /** @format uuid */
    bankTransactionId?: string
    internalComment?: string
    type?: RelTxnInstallmentRepaymentType
    amount?: number
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency?: IsoCurrencyCode
    /** @format date */
    effectiveDate?: string
  }

export enum RelTxnInstallmentRepaymentType {
  PRINCIPAL = 'PRINCIPAL',
  FEE = 'FEE',
  PROLONGATION = 'PROLONGATION',
  PENALTY = 'PENALTY',
  FEEREMINDER1 = 'FEE_REMINDER1',
  FEEREMINDER2 = 'FEE_REMINDER2'
}

export type Representative = BasePartyOrganizationEntity &
  BaseMetadataEntity & {
    roleText?: string
    statutory: boolean
    onBehalf?: boolean
    /** @format uuid */
    userId?: string
    sourceType: PartySourceType
    /** @format date-time */
    registerDataAt?: string
    /** @format date-time */
    registerEffectiveStatutoryAt?: string
    /** @format int32 */
    distance?: number
    intermediatePartyNames?: string[]
  }

export interface RepresentativeDtoRelUserParty {
  userParty: Party
  state: RelPartyUserPartyState
  /** @format date-time */
  stateUpdatedAt: string
  /** @format uuid */
  stateUpdatedBy: string
}

export interface RepresentativeDto1 {
  /** @format uuid */
  id: string
  party: Party
  roleText: string
  intermediatePartyNames: string[]
  statutory: boolean
  /** @format date-time */
  effectiveFrom: string
  /** @format date-time */
  effectiveTo: string
  /** Boolean flag indicating if KYC verification is valid */
  kycValid?: boolean
  /**
   * KYC valid to (only relevant if KYC valid)
   * @format date
   */
  kycValidTo?: string
  validKycApplicantId: string
  /** State of recent KYC verification (maybe approved, rejected or in progress */
  recentKycState?: KycVerificationState
  recentKycApplicantId: string
  /** @deprecated */
  kycApplicantId: string
  /** @format uuid */
  userId: string
  userParties: RepresentativeDtoRelUserParty[]
  user: User
  onBehalf: boolean
  /** @format int64 */
  version: number
}

export interface ReturnToCheckingInputDto {
  note: string
}

export interface RevenueDtoDailyRevenueDto {
  branchId?: string
  /** @format date */
  date?: string
  revenue?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  isForecast?: boolean
}

export interface RevenueDtoMonthlyRevenueDto {
  dailyRevenues?: RevenueDtoDailyRevenueDto[]
}

export type RevenuesByPartnerAndMonth = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format date */
    month?: string
    partnerCode?: string
    amount?: number
  }

export interface RevenuesReportDto {
  partnerCodes?: string[]
  monthlyPartnerRevenues?: any
  yearlyRevenues?: any
  summary?: RevenuesReportDtoSummary
}

export interface RevenuesReportDtoRevenue {
  revenue?: number
  isForecast?: boolean
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
}

export interface RevenuesReportDtoSummary {
  monthlyRevenues?: any
  yearlyRevenues?: any
}

export interface RevenuesReportDtoYearlyData {
  /** @format int32 */
  year?: number
  yearlyPartnerRevenues?: any
  quarterlyPartnerRevenues?: any
}

export interface RevenuesReportDtoYearlySummaryData {
  yearlyRevenue?: RevenuesReportDtoRevenue
  quarterlyRevenues?: any
}

export type RiskEvaluation = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    customerId?: string
    partnerCode?: string
    merchantId?: string
    type: RiskEvaluationType
    /** ISO 3166 alpha-2 country code enum, including metadata. */
    country?: IsoCountryCode
    businessIdHash?: string
    grades?: RiskEvaluationGrade[]
    /** @format date */
    observationDate?: string
    /** @format uuid */
    batchId?: string | null
    /** @format date */
    firstTransactionDate?: string
    /** @format date */
    lastTransactionDate?: string
  }

export type RiskEvaluationGrade = BaseMetadataEntity & {
  product: ProductType
  /** @format uuid */
  riskEvaluationId: string
  /** @format int32 */
  riskGrade?: number
  forecastedRevenue?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  predictedPd?: number
  modelId?: string
  partial_scores?: any
}

export enum RiskEvaluationType {
  BUSINESS_DATA = 'BUSINESS_DATA',
  OPEN_BANKING = 'OPEN_BANKING',
  SENTIMENT = 'SENTIMENT'
}

export interface RiskGradeDto {
  /** @format int32 */
  riskGradePartner?: number
  /** @format int32 */
  forecastedRevenue?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
}

export interface RiskGradeSummary {
  partnerProductSummaries?: any
  aggregatedProductSummary?: {
    M1?: AggregatedRiskGradeDto
    M3?: AggregatedRiskGradeDto
    M3R?: AggregatedRiskGradeDto
    M6?: AggregatedRiskGradeDto
    M12?: AggregatedRiskGradeDto
  }
}

export type RiskProfile = BaseIdEntity &
  BaseMetadataEntity & {
    tagsArray?: string[]
    /** @format uuid */
    partyId: string
    /** @format uuid */
    customerScoringId?: string
    /** @format date-time */
    validAt: string
    capital?: number
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    capitalCurrency?: IsoCurrencyCode
    vatPayer?: boolean
    /** @format date */
    vatPayerSince?: string
    /** @format int32 */
    employeeCountFrom?: number
    /** @format int32 */
    employeeCountTo?: number
    /** @format int32 */
    employeeCountCode?: number
    /** @format date */
    employeeCountAt?: string
    hasPremises?: boolean
    bankAccounts?: string[]
    tags?: RiskProfileTag[]
  }

export interface RiskProfileDetailDto {
  riskProfile?: RiskProfile
  externalDatas?: ExternalData[]
}

export enum RiskProfileTag {
  UNRELIABLE_PAYER = 'UNRELIABLE_PAYER',
  RESIDENCE_AT_TOWN_HALL = 'RESIDENCE_AT_TOWN_HALL',
  VIRTUAL_RESIDENCY = 'VIRTUAL_RESIDENCY',
  INSOLVENCY_RISK = 'INSOLVENCY_RISK',
  INSOLVENCY_RISK_OF_ANOTHER_COMPANY = 'INSOLVENCY_RISK_OF_ANOTHER_COMPANY',
  INSOLVENCY = 'INSOLVENCY',
  INSOLVENCY_OF_ANOTHER_COMPANY = 'INSOLVENCY_OF_ANOTHER_COMPANY',
  EXECUTION = 'EXECUTION',
  EXECUTION_OF_ANOTHER_COMPANY = 'EXECUTION_OF_ANOTHER_COMPANY',
  LIQUIDATION = 'LIQUIDATION',
  BANKRUPTCY = 'BANKRUPTCY',
  CANCELED_COMPANY = 'CANCELED_COMPANY',
  CRIMINAL_RECORD = 'CRIMINAL_RECORD',
  DEBT_INSTITUTION = 'DEBT_INSTITUTION',
  BLACKLISTED = 'BLACKLISTED',
  BLACKLIST_OF_ANOTHER_COMPANY = 'BLACKLIST_OF_ANOTHER_COMPANY',
  COMPANY_NAME = 'COMPANY_NAME',
  VAT_PAYER_NO_BANK_ACCOUNT = 'VAT_PAYER_NO_BANK_ACCOUNT',
  NOOWNEROVER30PERCENT = 'NO_OWNER_OVER_30_PERCENT',
  INSUFFICIENT_OWNERSHIP_COVERAGE = 'INSUFFICIENT_OWNERSHIP_COVERAGE',
  APPLICANTS_VS_CREDITCHECK_MISMATCH = 'APPLICANTS_VS_CREDITCHECK_MISMATCH',
  APPLICANTS_VS_DOCUMENTS_MISMATCH = 'APPLICANTS_VS_DOCUMENTS_MISMATCH',
  PEP_APPLICANT = 'PEP_APPLICANT',
  NO_WORKPLACE = 'NO_WORKPLACE',
  EXECUTION_RISK = 'EXECUTION_RISK',
  EXECUTION_RISK_OF_ANOTHER_COMPANY = 'EXECUTION_RISK_OF_ANOTHER_COMPANY',
  BANK_ACCOUNT_DATA = 'BANK_ACCOUNT_DATA',
  BANK_ACCOUNT_HOLDER = 'BANK_ACCOUNT_HOLDER',
  NEW_COMPANY = 'NEW_COMPANY',
  COMPANYOWNSMORETHAN50PERCENT = 'COMPANY_OWNS_MORE_THAN_50_PERCENT',
  CREDIT_CHECK_ERROR = 'CREDIT_CHECK_ERROR',
  DISTRAINT_CHECK_ERROR = 'DISTRAINT_CHECK_ERROR',
  INCOMPLETE_REPRESENTATIVE_KEY = 'INCOMPLETE_REPRESENTATIVE_KEY',
  INCOMPLETE_OWNER_KEY = 'INCOMPLETE_OWNER_KEY',
  MISSING_REGISTER_ENTRY = 'MISSING_REGISTER_ENTRY',
  INCOMPLETE_REGISTER_COVERAGE = 'INCOMPLETE_REGISTER_COVERAGE',
  COLLATERAL_SHARE = 'COLLATERAL_SHARE',
  CANCELATION_RISK = 'CANCELATION_RISK',
  HISTORICAL_DEBT_INSTITUTION = 'HISTORICAL_DEBT_INSTITUTION',
  INCOMPLETE_DISTRAINT_CHECK_KEY = 'INCOMPLETE_DISTRAINT_CHECK_KEY',
  INCOMPLETE_DISTRAINT_CHECK_COVERAGE = 'INCOMPLETE_DISTRAINT_CHECK_COVERAGE',
  VAT_ACCOUNT_NOT_CONNECTED = 'VAT_ACCOUNT_NOT_CONNECTED',
  FOREIGNER = 'FOREIGNER',
  BUSINESS_DATA_OLDER_THAN_ESTABLISHMENT = 'BUSINESS_DATA_OLDER_THAN_ESTABLISHMENT',
  OPEN_BANKING_PIPELINE_ERROR = 'OPEN_BANKING_PIPELINE_ERROR',
  INCOMPLETE_STATUTORY_STRUCTURE = 'INCOMPLETE_STATUTORY_STRUCTURE',
  MANUAL_OR_OUTDATED_STATUTORIES = 'MANUAL_OR_OUTDATED_STATUTORIES',
  CREDIT_CHECKED = 'CREDIT_CHECKED',
  DISTRAINT_CHECKED = 'DISTRAINT_CHECKED',
  HAS_WORKPLACE = 'HAS_WORKPLACE',
  CANCELED_VAT_REGISTRATION = 'CANCELED_VAT_REGISTRATION',
  OPEN_BANKING_PIPELINE_NO_DATA = 'OPEN_BANKING_PIPELINE_NO_DATA',
  MANUAL_OR_OUTDATED_STATUTORY = 'MANUAL_OR_OUTDATED_STATUTORY',
  COMPANY_STATUTORIES = 'COMPANY_STATUTORIES'
}

export enum RiskProfileTagSeverity {
  NONE = 'NONE',
  INFO = 'INFO',
  MANUAL_CHECK = 'MANUAL_CHECK',
  KO = 'KO'
}

export interface ScoreCard {
  score?: number
  scoreDetail?: any
  /** @format date-time */
  scoreAt?: string
}

export interface ServiceActivationRequestActivationUbo {
  /** First name */
  firstName: string
  /** Last name */
  surname: string
  /**
   * Date of birth
   * @format date
   * @example "1990-01-01T00:00:00.000Z"
   */
  dateOfBirth: string
  /**
   * UBO's nationality, country code as defined by ISO 3166-1 alpha-2
   * @example CZ
   */
  nationality: string
  /** Street address */
  street: string
  /** Name of the city */
  city: string
  /** ZIP code */
  zip: string
  /**
   * UBO's country of residence corresponding to the provided address. Country code as defined by ISO 3166-1 alpha-2
   * @example CZ
   */
  countryOfResidence: string
  /** PEP declaration */
  pepDeclaration: boolean
}

export interface ServiceActivationRequestCompany {
  /** Identification number of the company in business register */
  businessId: string
  /**
   * Two-letter country code as defined by ISO 3166-1 alpha-2
   * @example CZ
   */
  country: string
  /** Bank account number for financing disbursement */
  iban?: string
  /** URL of company's website */
  web?: string
  /**
   * Email address
   * @format email
   */
  email: string
  /** Phone number in international format E.123 */
  phone?: string
  /** Product category */
  productCategory?: string
}

export enum ServicingActionType {
  RESCHEDULED = 'RESCHEDULED',
  RESTRUCTURED = 'RESTRUCTURED',
  DEFERRED1 = 'DEFERRED1',
  DEFERRED2 = 'DEFERRED2',
  INSOLVENCY = 'INSOLVENCY',
  FORECLOSURE = 'FORECLOSURE',
  MANUAL_DEFERMENT = 'MANUAL_DEFERMENT'
}

export type SetRiskProfileTag = CollectionRiskProfileTag & {
  empty?: boolean
}

export interface Sort {
  orderBy: SortOrder[]
}

export interface SortOrder {
  ignoreCase: boolean
  direction: SortOrderDirection
  property: string
  ascending?: boolean
}

export enum SortOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface TermsDto {
  /** @format uuid */
  id: string
  /** Countries supported by FlowPay */
  country: FlowpayCountry
  revision: string
  fileName: string
  /** @format date */
  effectiveFrom: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
  /** @format int64 */
  version: number
}

export interface TestResourceBenchmarkRecord {
  /** @format int64 */
  calls?: number
  /** @format double */
  seconds?: number
  /** @format double */
  callsPerSecond?: number
  /** @format double */
  secondsPerCall?: number
}

export enum TransactionDirection {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export interface TriggerRiskEvaluationsImportDto {
  url: string
}

export interface TxnBasicDashboardDto {
  /** @format date */
  latestTxUpdate?: string
  /** @format date */
  latestTxDate?: string
  /** @format date */
  earliestTxDate?: string
  categoryAmounts?: CategoryAmounts[]
  monthlyAmounts?: MonthlyAmounts[]
}

export interface TxnDashboardHeaderDto {
  companyName?: string
  companyRegNum?: string
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currencyCode?: IsoCurrencyCode
  accounts?: PsdAccountPreview[]
}

export interface TxnDetailInfoDashboardDto {
  topCreditorsByAmountSum?: CounterPartyAmountSum[]
  topCreditorsByTxCount?: CounterPartyTxCount[]
  topDebtorsByAmountSum?: CounterPartyAmountSum[]
  topDebtorsByTxCount?: CounterPartyTxCount[]
  intercompanyCreditAccounts?: CounterPartyAmountSum[]
  intercompanyDebitAccounts?: CounterPartyAmountSum[]
  recurrentCreditPayments?: RecurrentTransactions[]
  recurrentDebitPayments?: RecurrentTransactions[]
}

export interface TxnDetailListDto {
  /** @format uuid */
  id?: string
  customer?: string
  direction?: TransactionDirection
  iban?: string
  ownerName?: string
  product?: string
  /** @format date */
  valueDate?: string
  /** @format date */
  bookingDate?: string
  amount?: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode
  counterAccount?: string
  counterName?: string
  remittanceInfo?: string
  vs?: string
  ss?: string
  subCategory?: string
  detailedCategory?: string
  category?: string
  sentiment?: string
  /** @format date-time */
  updatedAt?: string
}

export interface TxnDetailedCategoriesDashboardDto {
  detailedCategoryAmounts?: DetailedCategoryAmount[]
}

export interface TxnDetailedOverviewDashboardDto {
  categoryAmountsLast6Months?: CategorySentimentAmounts[]
  categoryAmountsOlderThan6Months?: CategorySentimentAmounts[]
}

export interface UnqualifiedSingleOfferDto {
  partnerCode?: string
  /** @format uuid */
  customerId?: string
  note?: string
}

export interface UpdateBankTransactionDto {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  amount: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
  vs?: string | null
  state: BankTransactionState
  iban: string
  txnCode?: string | null
  bankRef?: string | null
  counterPartyIban?: string | null
  counterPartyName?: string | null
  counterPartyComment?: string | null
  internalComment?: string | null
  /** @format int64 */
  version: number
}

export interface UpdateBlacklistDto {
  note: string
  /** @format int32 */
  effectivePeriodMonths?: number | null
}

export interface UpdateCollectionFeeDto {
  /** @format uuid */
  id?: string
  type?: CollectionFeeCollectionFeeType
  state?: CollectionFeeCollectionFeeState
  /** @format date */
  effectiveDate?: string
  total?: number
  vs?: string
  counterPartyIban?: string
  /** @format int64 */
  version?: number
}

export interface UpdateContactAdminDto {
  /** @format uuid */
  id: string
  /** @format date-time */
  effectiveAt: string
  channel: string
  message: string
  /** @format int64 */
  version?: number
}

export interface UpdateCustomerDto {
  name: string
  legalForm: string
  regCourt: string
  fop: boolean
  /** @format date */
  regSince: string
  /** @format email */
  email: string
  phone: string
  web: string
  address: UpdateCustomerDtoAddressDto
}

export interface UpdateCustomerDtoAddressDto {
  line1?: string | null
  line2?: string | null
  city?: string | null
  zip?: string | null
  country?: string | null
}

export interface UpdateCustomerRepDto {
  firstName: string
  lastName: string
  /** @format date */
  dob: string
  address: UpdateCustomerRepDtoAddressDto
  reg: UpdateCustomerRepDtoRegDto
  user: UpdateCustomerRepDtoUserDto
}

export interface UpdateCustomerRepDtoAddressDto {
  line1?: string | null
  line2?: string | null
  city?: string | null
  zip?: string | null
  country?: string | null
}

export interface UpdateCustomerRepDtoRegDto {
  roleText: string
  statutory: boolean
  /** @format date */
  effectiveFrom: string
  /** @format date */
  effectiveTo: string
  intermediatePartyNames: string[]
}

export interface UpdateCustomerRepDtoUserDto {
  /** @format email */
  email: string
  phone: string
  pep: boolean
}

export interface UpdateCustomerRiskNoteDto {
  note: string
}

export interface UpdateDebtProviderDto {
  /** @format uuid */
  debtProviderId?: string | null
}

export interface UpdateInstallmentDto {
  /** @format uuid */
  id?: string
  state?: InstallmentInstallmentState
  /** @format date */
  dueDate?: string
  type?: InstallmentInstallmentType
  principal?: number
  fee?: number
  feeProlongation?: number
  feePenalty?: number
  feeReminder1?: number
  feeReminder2?: number
  total?: number
  /** @format date */
  paidDate?: string
  paidPrincipal?: number
  paidFee?: number
  paidFeeProlongation?: number
  paidFeePenalty?: number
  paidFeeReminder1?: number
  paidFeeReminder2?: number
  paidTotal?: number
  paymentNote?: string
  feePenaltyCalculated?: number
  /** @format date */
  feePenaltyCalculatedDate?: string
  /** @format int64 */
  version?: number
}

export interface UpdateOfferDto {
  partnerCode: string
  merchantId: string
  products: UpdateOfferDtoUpdateOfferProductDto[]
}

export interface UpdateOfferDtoUpdateOfferProductDto {
  product: ProductType
  ratePerc: number
  maxAmount: number
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  riskGrade: number
  revenueForecast: number
}

export interface UpdateOwnerDto {
  sharePerc: number
  ubo: boolean
  /** @format date-time */
  effectiveFrom: string
  /** @format date-time */
  effectiveTo: string
}

export interface UpdateRepresentativeDto {
  roleText: string
  statutory: boolean
  /** @format date-time */
  effectiveFrom: string
  /** @format date-time */
  effectiveTo: string
}

export type User = BaseIdEntity &
  BaseMetadataEntity & {
    type: UserType
    authId: string
    username: string
    signupContext?: string | null
    invited: boolean
  }

export interface UserAdminDto {
  name?: string
  /** @format email */
  email?: string
}

export interface UserCreateResponseDto {
  /** @format uuid */
  fpUserId?: string
}

export interface UserLinkDto {
  /** @format email */
  email: string
  authId?: string
  authConnection?: string
  authProvider?: string
  authIsSocial?: boolean
}

export interface UserSignUpDto {
  authId: string
  /** @format email */
  email: string
  firstName?: string
  lastName?: string
  /** @format uuid */
  leadId?: string
  /** @format uuid */
  invitedCustomerId?: string
  invitedPartnerCode?: string
  context?: string
}

export enum UserType {
  CUSTOMER = 'CUSTOMER',
  PARTNER = 'PARTNER',
  FLOWPAY = 'FLOWPAY'
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://api.my.flowpay.io'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key])
    return keys
      .map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
    }).then(async response => {
      const r = response as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then(data => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch(e => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title myFlowpay Admin API
 * @version 1.0.0
 * @termsOfService https://flowpay.io/en/terms
 * @baseUrl https://api.my.flowpay.io
 * @contact Flowpay <info@flowpay.io> (https://flowpay.io)
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  apiAdmin = {
    /**
     * No description
     *
     * @tags Applications
     * @name GetApplicationList
     * @summary Get applications
     * @request GET:/api-admin/v1/applications
     * @secure
     */
    getApplicationList: (params: RequestParams = {}) =>
      this.request<ApplicationAdminListItemDto[], any>({
        path: `/api-admin/v1/applications`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name GetApplicationByLeadId
     * @summary Get application detail by leadId
     * @request GET:/api-admin/v1/applications/lead/{leadId}
     * @secure
     */
    getApplicationByLeadId: (leadId: string, params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/api-admin/v1/applications/lead/${leadId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name GetApplicationsList
     * @summary Get applications
     * @request GET:/api-admin/v1/applications/list
     * @secure
     */
    getApplicationsList: (
      query?: {
        state?: ApplicationState
        loanState?: LoanLoanState
        collectionState?: CollectionActionType
        servicingTags?: ServicingActionType[]
        countryCode?: string
        fulltext?: string
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<ApplicationAdminListItemDto[], any>({
        path: `/api-admin/v1/applications/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name UpdateBankAccount
     * @summary Update bank account
     * @request PATCH:/api-admin/v1/applications/{applicationId}/bank-account
     * @secure
     */
    updateBankAccount: (applicationId: string, data: ApplicationSubmitBankDto, params: RequestParams = {}) =>
      this.request<Application, void>({
        path: `/api-admin/v1/applications/${applicationId}/bank-account`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name GetApplicationContacts
     * @summary Get application contacts
     * @request GET:/api-admin/v1/applications/{applicationId}/contacts
     * @secure
     */
    getApplicationContacts: (applicationId: string, params: RequestParams = {}) =>
      this.request<Contact[], any>({
        path: `/api-admin/v1/applications/${applicationId}/contacts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name CreateCustomerContact
     * @summary Create customer contact
     * @request POST:/api-admin/v1/applications/{applicationId}/contacts
     * @secure
     */
    createCustomerContact: (applicationId: string, data: UpdateContactAdminDto, params: RequestParams = {}) =>
      this.request<ContactAdminDto, any>({
        path: `/api-admin/v1/applications/${applicationId}/contacts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name DeleteApplicationContact
     * @summary Delete application contact
     * @request DELETE:/api-admin/v1/applications/{applicationId}/contacts/{contactId}
     * @secure
     */
    deleteApplicationContact: (applicationId: string, contactId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/applications/${applicationId}/contacts/${contactId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name UpdateCustomerContact
     * @summary Update customer contact
     * @request PATCH:/api-admin/v1/applications/{applicationId}/contacts/{contactId}
     * @secure
     */
    updateCustomerContact: (
      applicationId: string,
      contactId: string,
      data: UpdateContactAdminDto,
      params: RequestParams = {}
    ) =>
      this.request<ContactAdminDto, any>({
        path: `/api-admin/v1/applications/${applicationId}/contacts/${contactId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name RecalculateFinancingParameters
     * @summary Recalculate financing parameters
     * @request POST:/api-admin/v1/applications/{applicationId}/recalculate-parameters
     * @secure
     */
    recalculateFinancingParameters: (
      applicationId: string,
      data: RecalculateParamsInputDto,
      params: RequestParams = {}
    ) =>
      this.request<RecalculateParamsOutputDto, any>({
        path: `/api-admin/v1/applications/${applicationId}/recalculate-parameters`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name GetApplication
     * @summary Get application details
     * @request GET:/api-admin/v1/applications/{id}
     * @secure
     */
    getApplication: (id: string, params: RequestParams = {}) =>
      this.request<ApplicationDetailDto, any>({
        path: `/api-admin/v1/applications/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationApproval
     * @summary Approve or reject application
     * @request POST:/api-admin/v1/applications/{id}/approval
     * @secure
     */
    applicationApproval: (id: string, data: ApplicationApprovalDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/applications/${id}/approval`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name Cancel
     * @summary Cancel application
     * @request POST:/api-admin/v1/applications/{id}/cancel
     * @secure
     */
    cancel: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/applications/${id}/cancel`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationClientRefused
     * @summary Set application state to refused by client
     * @request POST:/api-admin/v1/applications/{id}/client-refused
     * @secure
     */
    applicationClientRefused: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/applications/${id}/client-refused`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name GetContract
     * @summary Get loan contract
     * @request GET:/api-admin/v1/applications/{id}/contract
     * @secure
     */
    getContract: (id: string, params: RequestParams = {}) =>
      this.request<Document, any>({
        path: `/api-admin/v1/applications/${id}/contract`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name RequestContractSignature
     * @summary Request contract signature
     * @request POST:/api-admin/v1/applications/{id}/contract-signature-request
     * @secure
     */
    requestContractSignature: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/applications/${id}/contract-signature-request`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name CreateContract
     * @summary Create loan contract
     * @request POST:/api-admin/v1/applications/{id}/create-contract
     * @secure
     */
    createContract: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api-admin/v1/applications/${id}/create-contract`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name InitiateDisbursement
     * @summary Initiate loan disbursement
     * @request POST:/api-admin/v1/applications/{id}/disbursement
     * @secure
     */
    initiateDisbursement: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/applications/${id}/disbursement`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @name GetPsdReport
     * @request GET:/api-admin/v1/applications/{id}/psdreport
     * @secure
     */
    getPsdReport: (
      id: string,
      query?: {
        withTransactions?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<PsdReportDto, any>({
        path: `/api-admin/v1/applications/${id}/psdreport`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SetRep2
     * @summary Set representative #2
     * @request POST:/api-admin/v1/applications/{id}/rep2
     * @secure
     */
    setRep2: (id: string, data: ApplicationSubmitRep2Dto, params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/api-admin/v1/applications/${id}/rep2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name DeleteRep2
     * @summary Delete representative #2
     * @request DELETE:/api-admin/v1/applications/{id}/rep2
     * @secure
     */
    deleteRep2: (id: string, params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/api-admin/v1/applications/${id}/rep2`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ReturnToChecking
     * @summary Returns application to the CHECKING state
     * @request POST:/api-admin/v1/applications/{id}/return-to-checking
     * @secure
     */
    returnToChecking: (id: string, data: ReturnToCheckingInputDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/applications/${id}/return-to-checking`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SetStateSigned
     * @summary Set state to SIGNED
     * @request POST:/api-admin/v1/applications/{id}/set-signed
     * @secure
     */
    setStateSigned: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/applications/${id}/set-signed`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags AuditLog
     * @name GetAuditLogEntryList
     * @summary Get audit log entries
     * @request GET:/api-admin/v1/auditlogentries
     * @secure
     */
    getAuditLogEntryList: (
      query?: {
        entityName?: string
        rowId?: string
        userId?: string
        /** @format date-time */
        tFrom?: string
        /** @format date-time */
        tTo?: string
        reverse?: boolean
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<AuditLogEntry[], any>({
        path: `/api-admin/v1/auditlogentries`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name GetEmbeddedAuditLogEntry
     * @request GET:/api-admin/v1/auditlogentries/entity/{entityId}
     * @secure
     */
    getEmbeddedAuditLogEntry: (entityId: string, params: RequestParams = {}) =>
      this.request<AuditLogEmbeddedEntryDto[], any>({
        path: `/api-admin/v1/auditlogentries/entity/${entityId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AuditLog
     * @name GetSchema
     * @summary Get schema (list of audited entities)
     * @request GET:/api-admin/v1/auditlogentries/schema
     * @secure
     */
    getSchema: (params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/api-admin/v1/auditlogentries/schema`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AuditLog
     * @name GetAuditLogEntry
     * @summary Get audit log entry details
     * @request GET:/api-admin/v1/auditlogentries/{id}
     * @secure
     */
    getAuditLogEntry: (id: number, params: RequestParams = {}) =>
      this.request<AuditLogEntry, any>({
        path: `/api-admin/v1/auditlogentries/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Bank accounts
     * @name GetBankAccountList
     * @summary Get list of bank accounts
     * @request GET:/api-admin/v1/bank-accounts
     * @secure
     */
    getBankAccountList: (params: RequestParams = {}) =>
      this.request<BankAccount[], any>({
        path: `/api-admin/v1/bank-accounts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags myFlowpayAdmin, myFlowpayAdmin.Common
     * @name GetBanks
     * @summary Get list of supported banks
     * @request GET:/api-admin/v1/banks
     * @secure
     */
    getBanks: (params: RequestParams = {}) =>
      this.request<BankDto[], any>({
        path: `/api-admin/v1/banks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Blacklists
     * @name GetAll
     * @summary Get all blacklists
     * @request GET:/api-admin/v1/blacklists
     * @secure
     */
    getAll: (params: RequestParams = {}) =>
      this.request<BlacklistListDto[], any>({
        path: `/api-admin/v1/blacklists`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Blacklists
     * @name ConvertToPermanent
     * @summary Convert temporary blacklist to permanent
     * @request POST:/api-admin/v1/blacklists/convert
     * @secure
     */
    convertToPermanent: (data: ConvertBlacklistDto, params: RequestParams = {}) =>
      this.request<BlacklistDto, void>({
        path: `/api-admin/v1/blacklists/convert`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Blacklists
     * @name GetById
     * @summary Get blacklist detail by ID
     * @request GET:/api-admin/v1/blacklists/{blacklistId}
     * @secure
     */
    getById: (blacklistId: string, params: RequestParams = {}) =>
      this.request<BlacklistDto, void>({
        path: `/api-admin/v1/blacklists/${blacklistId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Blacklists
     * @name Update
     * @summary Update blacklist by ID
     * @request PATCH:/api-admin/v1/blacklists/{blacklistId}
     * @secure
     */
    update: (blacklistId: string, data: UpdateBlacklistDto, params: RequestParams = {}) =>
      this.request<BlacklistDto, void>({
        path: `/api-admin/v1/blacklists/${blacklistId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Blacklists
     * @name Deactivate
     * @summary Deactivate blacklist (set effectiveTo date to today's date)
     * @request PUT:/api-admin/v1/blacklists/{blacklistId}/deactivate
     * @secure
     */
    deactivate: (blacklistId: string, params: RequestParams = {}) =>
      this.request<BlacklistDto, void>({
        path: `/api-admin/v1/blacklists/${blacklistId}/deactivate`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name GetCollectionList
     * @summary Get list of financings in collection state
     * @request GET:/api-admin/v1/collections
     * @secure
     */
    getCollectionList: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/api-admin/v1/collections`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name DeleteCollectionFee
     * @summary Delete collection fee
     * @request DELETE:/api-admin/v1/collections/fees/{collectionFeeId}
     * @secure
     */
    deleteCollectionFee: (collectionFeeId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/collections/fees/${collectionFeeId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name UpdateCollectionFee
     * @summary Update collection fee
     * @request PATCH:/api-admin/v1/collections/fees/{collectionFeeId}
     * @secure
     */
    updateCollectionFee: (collectionFeeId: string, data: UpdateCollectionFeeDto, params: RequestParams = {}) =>
      this.request<CollectionFee, any>({
        path: `/api-admin/v1/collections/fees/${collectionFeeId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name GetCollectionFeeTxnList
     * @summary Get collection fee transactions
     * @request GET:/api-admin/v1/collections/fees/{collectionFeeId}/txns
     * @secure
     */
    getCollectionFeeTxnList: (collectionFeeId: string, params: RequestParams = {}) =>
      this.request<RelTxnCollectionFee[], any>({
        path: `/api-admin/v1/collections/fees/${collectionFeeId}/txns`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name TerminateCollection
     * @summary Terminate collection
     * @request DELETE:/api-admin/v1/collections/{collectionId}
     * @secure
     */
    terminateCollection: (collectionId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api-admin/v1/collections/${collectionId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name CreateCollectionAction
     * @summary Create collection action
     * @request POST:/api-admin/v1/collections/{collectionId}/action
     * @secure
     */
    createCollectionAction: (
      collectionId: string,
      data: CollectionActionWithInitialNoteDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api-admin/v1/collections/${collectionId}/action`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name CreateCollectionActionNote
     * @summary Create collection action note
     * @request POST:/api-admin/v1/collections/{collectionId}/action/{collectionActionId}/note
     * @secure
     */
    createCollectionActionNote: (
      collectionId: string,
      collectionActionId: string,
      data: CollectionActionNote,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api-admin/v1/collections/${collectionId}/action/${collectionActionId}/note`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name GetCollectionActions
     * @summary Get collection actions
     * @request GET:/api-admin/v1/collections/{collectionId}/actions
     * @secure
     */
    getCollectionActions: (collectionId: string, params: RequestParams = {}) =>
      this.request<CollectionActionDto[], void>({
        path: `/api-admin/v1/collections/${collectionId}/actions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name GetAvailableCollectionActionTypes
     * @summary Get available collection action types
     * @request GET:/api-admin/v1/collections/{collectionId}/available-action-types
     * @secure
     */
    getAvailableCollectionActionTypes: (collectionId: string, params: RequestParams = {}) =>
      this.request<CollectionActionType[], void>({
        path: `/api-admin/v1/collections/${collectionId}/available-action-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name GetCollectionFeeList
     * @summary Get collection fees
     * @request GET:/api-admin/v1/collections/{collectionId}/fees
     * @secure
     */
    getCollectionFeeList: (collectionId: string, params: RequestParams = {}) =>
      this.request<CollectionFeeDto[], any>({
        path: `/api-admin/v1/collections/${collectionId}/fees`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name CreateCollectionFee
     * @summary Create collection fee
     * @request POST:/api-admin/v1/collections/{collectionId}/fees
     * @secure
     */
    createCollectionFee: (collectionId: string, data: UpdateCollectionFeeDto, params: RequestParams = {}) =>
      this.request<CollectionFee, any>({
        path: `/api-admin/v1/collections/${collectionId}/fees`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name GetCollectionListForLoan
     * @summary Get list of collections for a given loan
     * @request GET:/api-admin/v1/collections/{loanId}
     * @secure
     */
    getCollectionListForLoan: (loanId: string, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/api-admin/v1/collections/${loanId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name CreateCollection
     * @summary Create new collection for loan
     * @request POST:/api-admin/v1/collections/{loanId}/create-collection
     * @secure
     */
    createCollection: (loanId: string, params: RequestParams = {}) =>
      this.request<Collection[], any>({
        path: `/api-admin/v1/collections/${loanId}/create-collection`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Collections
     * @name StartCollectionProcess
     * @summary Start collection process for loan
     * @request POST:/api-admin/v1/collections/{loanId}/start-collection-process/{startDate}
     * @secure
     */
    startCollectionProcess: (loanId: string, startDate: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/collections/${loanId}/start-collection-process/${startDate}`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Commissions
     * @name GetList
     * @summary Get commissions
     * @request GET:/api-admin/v1/commissions
     * @secure
     */
    getList: (params: RequestParams = {}) =>
      this.request<CommissionAdminDto[], any>({
        path: `/api-admin/v1/commissions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customer Risk Notes
     * @name Create
     * @summary Create a new customer risk note
     * @request POST:/api-admin/v1/customer-risk-notes
     * @secure
     */
    create: (data: CreateCustomerRiskNoteDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api-admin/v1/customer-risk-notes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customer Risk Notes
     * @name FindAllByApplicationId
     * @summary Get all customer risk notes by scoring associated with an application with associated ID
     * @request GET:/api-admin/v1/customer-risk-notes/application/{applicationId}
     * @secure
     */
    findAllByApplicationId: (applicationId: string, params: RequestParams = {}) =>
      this.request<CustomerRiskNoteDto[], any>({
        path: `/api-admin/v1/customer-risk-notes/application/${applicationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customer Risk Notes
     * @name FindAllByCustomerId
     * @summary Get all customer risk notes by customer ID
     * @request GET:/api-admin/v1/customer-risk-notes/customer/{customerId}
     * @secure
     */
    findAllByCustomerId: (customerId: string, params: RequestParams = {}) =>
      this.request<CustomerRiskNoteDto[], any>({
        path: `/api-admin/v1/customer-risk-notes/customer/${customerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customer Risk Notes
     * @name FindAllByCustomerOrScoring
     * @summary Get all customer risk notes by customer scoring ID
     * @request GET:/api-admin/v1/customer-risk-notes/customer/{customerId}/scoring/{customerScoringId}
     * @secure
     */
    findAllByCustomerOrScoring: (customerId: string, customerScoringId: string, params: RequestParams = {}) =>
      this.request<CustomerRiskNoteDto[], any>({
        path: `/api-admin/v1/customer-risk-notes/customer/${customerId}/scoring/${customerScoringId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customer Risk Notes
     * @name GetById1
     * @summary Get a customer risk note by ID
     * @request GET:/api-admin/v1/customer-risk-notes/{noteId}
     * @secure
     */
    getById1: (noteId: string, params: RequestParams = {}) =>
      this.request<CustomerRiskNoteDto, void>({
        path: `/api-admin/v1/customer-risk-notes/${noteId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customer Risk Notes
     * @name DeactivateNote
     * @summary Deactivate a customer risk note
     * @request DELETE:/api-admin/v1/customer-risk-notes/{noteId}
     * @secure
     */
    deactivateNote: (noteId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api-admin/v1/customer-risk-notes/${noteId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customer Risk Notes
     * @name Update1
     * @summary Update a customer risk note
     * @request PATCH:/api-admin/v1/customer-risk-notes/{noteId}
     * @secure
     */
    update1: (noteId: string, data: UpdateCustomerRiskNoteDto, params: RequestParams = {}) =>
      this.request<CustomerRiskNoteDto, void>({
        path: `/api-admin/v1/customer-risk-notes/${noteId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerList
     * @summary Get customers
     * @request GET:/api-admin/v1/customers
     * @secure
     */
    getCustomerList: (params: RequestParams = {}) =>
      this.request<CustomerListItemDto[], any>({
        path: `/api-admin/v1/customers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name GetCustomersWithActiveFinancingWithoutActiveConnection
     * @request GET:/api-admin/v1/customers/inactive-psd-connections
     * @secure
     */
    getCustomersWithActiveFinancingWithoutActiveConnection: (
      query?: {
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<CustomerWithContactsDto[], any>({
        path: `/api-admin/v1/customers/inactive-psd-connections`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerAdminList
     * @summary Get customers
     * @request GET:/api-admin/v1/customers/list
     * @secure
     */
    getCustomerAdminList: (
      query?: {
        /** ISO 3166 alpha-2 country code enum, including metadata. */
        countryCode?: IsoCountryCode
        fulltext?: string
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<CustomerListItemDto[], any>({
        path: `/api-admin/v1/customers/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateCustomer
     * @summary Update customer
     * @request PATCH:/api-admin/v1/customers/{customerId}
     * @secure
     */
    updateCustomer: (customerId: string, data: UpdateCustomerDto, params: RequestParams = {}) =>
      this.request<Party, any>({
        path: `/api-admin/v1/customers/${customerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetApplications
     * @summary Get applications
     * @request GET:/api-admin/v1/customers/{customerId}/applications
     * @secure
     */
    getApplications: (customerId: string, params: RequestParams = {}) =>
      this.request<ApplicationWithRevenuesAdminListItemDto[], any>({
        path: `/api-admin/v1/customers/${customerId}/applications`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Blacklists
     * @name GetAllByCustomerId
     * @summary Get all blacklists for respective customer
     * @request GET:/api-admin/v1/customers/{customerId}/blacklists
     * @secure
     */
    getAllByCustomerId: (customerId: string, params: RequestParams = {}) =>
      this.request<BlacklistListDto[], any>({
        path: `/api-admin/v1/customers/${customerId}/blacklists`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Blacklists
     * @name CreateBlacklist
     * @summary Create a new blacklist entry for respective customer
     * @request POST:/api-admin/v1/customers/{customerId}/blacklists
     * @secure
     */
    createBlacklist: (customerId: string, data: CreateBlacklistDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api-admin/v1/customers/${customerId}/blacklists`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCommunicationHistory
     * @summary Get communication history
     * @request GET:/api-admin/v1/customers/{customerId}/communication-history
     * @secure
     */
    getCommunicationHistory: (customerId: string, params: RequestParams = {}) =>
      this.request<CommunicationHistoryEntryDto[], any>({
        path: `/api-admin/v1/customers/${customerId}/communication-history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerContacts
     * @summary Get customer contacts
     * @request GET:/api-admin/v1/customers/{customerId}/contacts
     * @secure
     */
    getCustomerContacts: (customerId: string, params: RequestParams = {}) =>
      this.request<Contact[], any>({
        path: `/api-admin/v1/customers/${customerId}/contacts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateCustomerContact1
     * @summary Create customer contact
     * @request POST:/api-admin/v1/customers/{customerId}/contacts
     * @secure
     */
    createCustomerContact1: (customerId: string, data: UpdateContactAdminDto, params: RequestParams = {}) =>
      this.request<ContactAdminDto, any>({
        path: `/api-admin/v1/customers/${customerId}/contacts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name DeleteCustomerContact
     * @summary Delete customer contact
     * @request DELETE:/api-admin/v1/customers/{customerId}/contacts/{contactId}
     * @secure
     */
    deleteCustomerContact: (customerId: string, contactId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/customers/${customerId}/contacts/${contactId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateCustomerContact1
     * @summary Update customer contact
     * @request PATCH:/api-admin/v1/customers/{customerId}/contacts/{contactId}
     * @secure
     */
    updateCustomerContact1: (
      customerId: string,
      contactId: string,
      data: UpdateContactAdminDto,
      params: RequestParams = {}
    ) =>
      this.request<ContactAdminDto, any>({
        path: `/api-admin/v1/customers/${customerId}/contacts/${contactId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetOffers
     * @summary Get offers
     * @request GET:/api-admin/v1/customers/{customerId}/offers
     * @secure
     */
    getOffers: (customerId: string, params: RequestParams = {}) =>
      this.request<Offer[], any>({
        path: `/api-admin/v1/customers/${customerId}/offers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetPsdConnections
     * @summary Get PSD connections
     * @request GET:/api-admin/v1/customers/{customerId}/psd-connections
     * @secure
     */
    getPsdConnections: (customerId: string, params: RequestParams = {}) =>
      this.request<PsdConnection[], any>({
        path: `/api-admin/v1/customers/${customerId}/psd-connections`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name SchedulePsdReport
     * @summary Schedule fresh PSD report aggregation
     * @request POST:/api-admin/v1/customers/{customerId}/psd-connections/{connectionId}/schedule
     * @secure
     */
    schedulePsdReport: (customerId: string, connectionId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api-admin/v1/customers/${customerId}/psd-connections/${connectionId}/schedule`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name GetPsdTxnDetail
     * @summary Get PSD transactions detail
     * @request GET:/api-admin/v1/customers/{customerId}/psd-transactions-detail
     * @secure
     */
    getPsdTxnDetail: (
      customerId: string,
      query?: {
        sentiments?: string[]
        /** @format date */
        dateFrom?: string
        /** @format date */
        dateTo?: string
        categories?: string[]
        subcategories?: string[]
        detailedCategories?: string[]
        amountFrom?: number
        amountTo?: number
        /** ISO 4217 alpha-3 currency code enum, including metadata. */
        currency?: IsoCurrencyCode
        ibans?: string[]
        fulltext?: string
        /** @format int32 */
        page?: number
        /** @format int32 */
        size?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<TxnDetailListDto[], any>({
        path: `/api-admin/v1/customers/${customerId}/psd-transactions-detail`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateCustomerRep
     * @summary Create representative
     * @request POST:/api-admin/v1/customers/{customerId}/representatives
     * @secure
     */
    createCustomerRep: (customerId: string, data: CreateCustomerRepDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api-admin/v1/customers/${customerId}/representatives`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name SetOnBehalf
     * @summary Set user on behalf
     * @request POST:/api-admin/v1/customers/{customerId}/representatives/on-behalf
     * @secure
     */
    setOnBehalf: (customerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/customers/${customerId}/representatives/on-behalf`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name RemoveOnBehalf
     * @summary Remove user on behalf
     * @request DELETE:/api-admin/v1/customers/{customerId}/representatives/on-behalf
     * @secure
     */
    removeOnBehalf: (customerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/customers/${customerId}/representatives/on-behalf`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateRepresentative
     * @summary Update representative
     * @request PATCH:/api-admin/v1/customers/{customerId}/representatives/{repId}
     * @deprecated
     * @secure
     */
    updateRepresentative: (
      customerId: string,
      repId: string,
      data: UpdateRepresentativeDto,
      params: RequestParams = {}
    ) =>
      this.request<Representative, any>({
        path: `/api-admin/v1/customers/${customerId}/representatives/${repId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateCustomerRep
     * @summary Update representative
     * @request PATCH:/api-admin/v1/customers/{customerId}/representatives/{repId}/new
     * @secure
     */
    updateCustomerRep: (customerId: string, repId: string, data: UpdateCustomerRepDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/customers/${customerId}/representatives/${repId}/new`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateRegFromUser
     * @summary Link KYC'ed user party with matching representatives
     * @request POST:/api-admin/v1/customers/{customerId}/representatives/{userPartyId}/create-reg-from-user
     * @secure
     */
    createRegFromUser: (customerId: string, userPartyId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api-admin/v1/customers/${customerId}/representatives/${userPartyId}/create-reg-from-user`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name LinkKycedUserWithRegReps
     * @summary Link KYC'ed user party with matching representatives
     * @request POST:/api-admin/v1/customers/{customerId}/representatives/{userPartyId}/link
     * @secure
     */
    linkKycedUserWithRegReps: (customerId: string, userPartyId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/customers/${customerId}/representatives/${userPartyId}/link`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerWithReps
     * @summary Get customer details
     * @request GET:/api-admin/v1/customers/{id}
     * @secure
     */
    getCustomerWithReps: (id: string, params: RequestParams = {}) =>
      this.request<CustomerDto, any>({
        path: `/api-admin/v1/customers/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name GetPsdAccounts
     * @request GET:/api-admin/v1/customers/{id}/bank-accounts
     * @secure
     */
    getPsdAccounts: (id: string, params: RequestParams = {}) =>
      this.request<PsdAccount[], any>({
        path: `/api-admin/v1/customers/${id}/bank-accounts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name GetPsdAccountTxns
     * @request GET:/api-admin/v1/customers/{id}/bank-accounts/{iban}/{currency}/txns
     * @secure
     */
    getPsdAccountTxns: (
      id: string,
      iban: string,
      currency: IsoCurrencyCode,
      query?: {
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<PsdAccount[], any>({
        path: `/api-admin/v1/customers/${id}/bank-accounts/${iban}/${currency}/txns`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerOwners
     * @summary Get customer owners
     * @request GET:/api-admin/v1/customers/{id}/owners
     * @secure
     */
    getCustomerOwners: (id: string, params: RequestParams = {}) =>
      this.request<OwnerDto[], any>({
        path: `/api-admin/v1/customers/${id}/owners`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name GetPsdReport1
     * @request GET:/api-admin/v1/customers/{id}/psdreport
     * @secure
     */
    getPsdReport1: (
      id: string,
      query?: {
        withTransactions?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<PsdReportDto, any>({
        path: `/api-admin/v1/customers/${id}/psdreport`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerRepresentatives
     * @summary Get customer representatives
     * @request GET:/api-admin/v1/customers/{id}/representatives
     * @secure
     */
    getCustomerRepresentatives: (id: string, params: RequestParams = {}) =>
      this.request<RepresentativeDto1[], any>({
        path: `/api-admin/v1/customers/${id}/representatives`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Scorings
     * @name ScheduleCustomerScoring
     * @summary Schedule fresh customer scoring
     * @request POST:/api-admin/v1/customers/{id}/scorings
     * @secure
     */
    scheduleCustomerScoring: (id: string, params: RequestParams = {}) =>
      this.request<CustomerScoring, any>({
        path: `/api-admin/v1/customers/${id}/scorings`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Debt Providers
     * @name GetAll1
     * @summary Get all debt providers
     * @request GET:/api-admin/v1/debt-providers
     * @secure
     */
    getAll1: (params: RequestParams = {}) =>
      this.request<DebtProviderDto[], any>({
        path: `/api-admin/v1/debt-providers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Debt Providers
     * @name GetDebtProviderById
     * @summary Get debt provider by id
     * @request GET:/api-admin/v1/debt-providers/{id}
     * @secure
     */
    getDebtProviderById: (id: string, params: RequestParams = {}) =>
      this.request<DebtProviderDto, void>({
        path: `/api-admin/v1/debt-providers/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentList
     * @summary Get documents
     * @request GET:/api-admin/v1/documents
     * @secure
     */
    getDocumentList: (params: RequestParams = {}) =>
      this.request<Document[], any>({
        path: `/api-admin/v1/documents`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentTypesForApplication
     * @summary Get document types for application related documents
     * @request GET:/api-admin/v1/documents/application/documenttypes
     * @secure
     */
    getDocumentTypesForApplication: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/api-admin/v1/documents/application/documenttypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentListByApplicationId
     * @summary Get documents for a given application
     * @request GET:/api-admin/v1/documents/application/{applicationId}
     * @secure
     */
    getDocumentListByApplicationId: (applicationId: string, params: RequestParams = {}) =>
      this.request<Document[], any>({
        path: `/api-admin/v1/documents/application/${applicationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name CreateApplicationDocument
     * @summary Create application related document
     * @request POST:/api-admin/v1/documents/application/{applicationId}
     * @secure
     */
    createApplicationDocument: (
      applicationId: string,
      data: {
        type: string
        /** @format binary */
        file: File
      },
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/api-admin/v1/documents/application/${applicationId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentTypesForParty
     * @summary Get document types for party related documents
     * @request GET:/api-admin/v1/documents/party/documenttypes
     * @secure
     */
    getDocumentTypesForParty: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/api-admin/v1/documents/party/documenttypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocumentListByPartyId
     * @summary Get documents for a given party
     * @request GET:/api-admin/v1/documents/party/{partyId}
     * @secure
     */
    getDocumentListByPartyId: (partyId: string, params: RequestParams = {}) =>
      this.request<Document[], any>({
        path: `/api-admin/v1/documents/party/${partyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name CreatePartyDocument
     * @summary Create party related document
     * @request POST:/api-admin/v1/documents/party/{partyId}
     * @secure
     */
    createPartyDocument: (
      partyId: string,
      data: {
        type: string
        /** @format binary */
        file: File
      },
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/api-admin/v1/documents/party/${partyId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name GetDocument
     * @summary Get document
     * @request GET:/api-admin/v1/documents/{id}
     * @secure
     */
    getDocument: (id: string, params: RequestParams = {}) =>
      this.request<DocumentDto, any>({
        path: `/api-admin/v1/documents/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name ListAll
     * @summary Find all financing parameters
     * @request GET:/api-admin/v1/financing-parameters
     * @secure
     */
    listAll: (params: RequestParams = {}) =>
      this.request<FinancingParameter[], any>({
        path: `/api-admin/v1/financing-parameters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name JobStatusChanged
     * @summary Receive job status changed event from Hotglue
     * @request POST:/api-admin/v1/hotglue/job-status-changed
     * @secure
     */
    jobStatusChanged: (data: string, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/api-admin/v1/hotglue/job-status-changed`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description .
     *
     * @tags Offers
     * @name GetInsightReport
     * @summary Get insight report for a given customer
     * @request GET:/api-admin/v1/insights/customers/{id}/insight-report
     * @secure
     */
    getInsightReport: (id: string, params: RequestParams = {}) =>
      this.request<InsightReportDto, any>({
        path: `/api-admin/v1/insights/customers/${id}/insight-report`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description .
     *
     * @tags Offers
     * @name GetAggregatedRevenues
     * @summary Get aggregated revenue insights for a given customer
     * @request GET:/api-admin/v1/insights/customers/{id}/revenues
     * @secure
     */
    getAggregatedRevenues: (id: string, params: RequestParams = {}) =>
      this.request<RevenuesReportDto, any>({
        path: `/api-admin/v1/insights/customers/${id}/revenues`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetInstallmentList
     * @summary Get installments
     * @request GET:/api-admin/v1/installments
     * @secure
     */
    getInstallmentList: (params: RequestParams = {}) =>
      this.request<InstallmentAdminDto[], any>({
        path: `/api-admin/v1/installments`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetInstallmentsAdminList
     * @summary Get installments admin list
     * @request GET:/api-admin/v1/installments/list
     * @secure
     */
    getInstallmentsAdminList: (
      query?: {
        state?: InstallmentInstallmentState
        type?: InstallmentInstallmentType
        fulltext?: string
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<InstallmentAdminDto[], any>({
        path: `/api-admin/v1/installments/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name DeleteInstallment
     * @summary Delete installment
     * @request DELETE:/api-admin/v1/installments/{id}
     * @secure
     */
    deleteInstallment: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/installments/${id}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name UpdateInstallment
     * @summary Update installment
     * @request PATCH:/api-admin/v1/installments/{id}
     * @secure
     */
    updateInstallment: (id: string, data: UpdateInstallmentDto, params: RequestParams = {}) =>
      this.request<InstallmentAdminDto, any>({
        path: `/api-admin/v1/installments/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetPairedTransactions
     * @summary Get transactions paired with an installment
     * @request GET:/api-admin/v1/installments/{id}/txns
     * @secure
     */
    getPairedTransactions: (id: string, params: RequestParams = {}) =>
      this.request<RelTxnInstallment[], any>({
        path: `/api-admin/v1/installments/${id}/txns`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name CommissionPaymentOrderJob
     * @summary Run commission payment order job
     * @request POST:/api-admin/v1/jobs/commission-payment-order
     * @secure
     */
    commissionPaymentOrderJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/commission-payment-order`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name CommissionStatementJob
     * @summary Run commission statement job
     * @request POST:/api-admin/v1/jobs/commission-statement
     * @secure
     */
    commissionStatementJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/commission-statement`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name CreditCheckMonitoringJob
     * @summary Run credit check monitoring job
     * @request POST:/api-admin/v1/jobs/credit-check-monitoring
     * @secure
     */
    creditCheckMonitoringJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/credit-check-monitoring`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name DaktelaSyncJob
     * @summary Run Daktela sync job
     * @request POST:/api-admin/v1/jobs/daktela-sync
     * @secure
     */
    daktelaSyncJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/daktela-sync`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name DownloadExchangeRatesJob
     * @summary Run Download Exchange Rates job
     * @request POST:/api-admin/v1/jobs/exchange-rates
     * @secure
     */
    downloadExchangeRatesJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/exchange-rates`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name InstallmentNotificationJob
     * @summary Run installment notification job
     * @request POST:/api-admin/v1/jobs/installment-notification
     * @secure
     */
    installmentNotificationJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/installment-notification`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name PairStatusJob
     * @summary Run pair transaction & status job
     * @request POST:/api-admin/v1/jobs/pair-status
     * @secure
     */
    pairStatusJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/pair-status`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name PaymentOrderJob
     * @summary Run payment order job
     * @request POST:/api-admin/v1/jobs/payment-order
     * @secure
     */
    paymentOrderJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/payment-order`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name PenaltyAndCommissionJob
     * @summary Run penalty & commission job
     * @request POST:/api-admin/v1/jobs/penalty-and-commission
     * @secure
     */
    penaltyAndCommissionJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/penalty-and-commission`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name PsdJob
     * @summary Run PSD job
     * @request POST:/api-admin/v1/jobs/psd
     * @secure
     */
    psdJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/psd`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name PsdConnectionNotificationJob
     * @summary Run PSD connection notification job
     * @request POST:/api-admin/v1/jobs/psd-connection-notifications
     * @secure
     */
    psdConnectionNotificationJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/psd-connection-notifications`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name SigniJob
     * @summary Run signi job
     * @request POST:/api-admin/v1/jobs/signi
     * @secure
     */
    signiJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/signi`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name BankTransactionStatusJob
     * @summary Run bank transaction job
     * @request POST:/api-admin/v1/jobs/transaction
     * @secure
     */
    bankTransactionStatusJob: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/jobs/transaction`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name VerificationReview
     * @summary Receive verification review webhook from SumSub
     * @request POST:/api-admin/v1/kyc-verifications/review
     * @secure
     */
    verificationReview: (data: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/kyc-verifications/review`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetLeads
     * @summary Get leads
     * @request GET:/api-admin/v1/leads
     * @secure
     */
    getLeads: (params: RequestParams = {}) =>
      this.request<Lead[], any>({
        path: `/api-admin/v1/leads`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetCustomerLeads
     * @summary Get customer leads
     * @request GET:/api-admin/v1/leads/customer/{customerId}
     * @secure
     */
    getCustomerLeads: (customerId: string, params: RequestParams = {}) =>
      this.request<Lead[], any>({
        path: `/api-admin/v1/leads/customer/${customerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetLeadsList
     * @summary Get leads
     * @request GET:/api-admin/v1/leads/list
     * @secure
     */
    getLeadsList: (
      query?: {
        state?: LeadState
        salesState?: LeadSalesState
        partnerCode?: string
        fulltext?: string
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<Lead[], any>({
        path: `/api-admin/v1/leads/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name DeleteLead
     * @summary Delete the lead
     * @request DELETE:/api-admin/v1/leads/{leadId}
     * @secure
     */
    deleteLead: (leadId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/leads/${leadId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name UpdateLead
     * @summary Update lead
     * @request PATCH:/api-admin/v1/leads/{leadId}
     * @secure
     */
    updateLead: (leadId: string, data: LeadUpdateDto, params: RequestParams = {}) =>
      this.request<Lead, any>({
        path: `/api-admin/v1/leads/${leadId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetLeadContacts
     * @summary Get lead contacts
     * @request GET:/api-admin/v1/leads/{leadId}/contacts
     * @secure
     */
    getLeadContacts: (leadId: string, params: RequestParams = {}) =>
      this.request<Contact[], any>({
        path: `/api-admin/v1/leads/${leadId}/contacts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name CreateLeadContact
     * @summary Create lead contact
     * @request POST:/api-admin/v1/leads/{leadId}/contacts
     * @secure
     */
    createLeadContact: (leadId: string, data: UpdateContactAdminDto, params: RequestParams = {}) =>
      this.request<ContactAdminDto, any>({
        path: `/api-admin/v1/leads/${leadId}/contacts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name DeleteLeadContact
     * @summary Delete lead contact
     * @request DELETE:/api-admin/v1/leads/{leadId}/contacts/{contactId}
     * @secure
     */
    deleteLeadContact: (leadId: string, contactId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/leads/${leadId}/contacts/${contactId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name UpdateLeadContact
     * @summary Update lead contact
     * @request PATCH:/api-admin/v1/leads/{leadId}/contacts/{contactId}
     * @secure
     */
    updateLeadContact: (leadId: string, contactId: string, data: UpdateContactAdminDto, params: RequestParams = {}) =>
      this.request<ContactAdminDto, any>({
        path: `/api-admin/v1/leads/${leadId}/contacts/${contactId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetLoanByApplicationId
     * @summary Get loan details by applicationId
     * @request GET:/api-admin/v1/loans
     * @secure
     */
    getLoanByApplicationId: (
      query: {
        /** @format uuid */
        applicationId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<Loan, any>({
        path: `/api-admin/v1/loans`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetLoanInvolvementsByCustomerId
     * @summary Get loan involvements by customer ID
     * @request GET:/api-admin/v1/loans/involvements/customer/{customerId}
     * @secure
     */
    getLoanInvolvementsByCustomerId: (customerId: string, params: RequestParams = {}) =>
      this.request<LoanInvolvementDto, any>({
        path: `/api-admin/v1/loans/involvements/customer/${customerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetLoanInvolvementsByPartyId
     * @summary Get loan involvements by person party ID
     * @request GET:/api-admin/v1/loans/involvements/person/{partyId}
     * @secure
     */
    getLoanInvolvementsByPartyId: (partyId: string, params: RequestParams = {}) =>
      this.request<LoanInvolvementDto, any>({
        path: `/api-admin/v1/loans/involvements/person/${partyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name GetReportPrincipalInCirculation
     * @summary Get report principal in circulation
     * @request GET:/api-admin/v1/loans/reports/principal-in-circulation
     * @secure
     */
    getReportPrincipalInCirculation: (params: RequestParams = {}) =>
      this.request<PrincipalInCirculationAggByPartnerAndMonth[], any>({
        path: `/api-admin/v1/loans/reports/principal-in-circulation`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name GetReportRevenues
     * @summary Get report revenues
     * @request GET:/api-admin/v1/loans/reports/revenues
     * @secure
     */
    getReportRevenues: (params: RequestParams = {}) =>
      this.request<RevenuesByPartnerAndMonth[], any>({
        path: `/api-admin/v1/loans/reports/revenues`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetLoan
     * @summary Get loan details
     * @request GET:/api-admin/v1/loans/{id}
     * @secure
     */
    getLoan: (id: string, params: RequestParams = {}) =>
      this.request<Loan, any>({
        path: `/api-admin/v1/loans/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SetAutoPenaltyFee
     * @summary Enable or disable automatic penalty fee for a given loan
     * @request POST:/api-admin/v1/loans/{id}/auto-penalty-fee
     * @secure
     */
    setAutoPenaltyFee: (
      id: string,
      data: {
        enabled?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api-admin/v1/loans/${id}/auto-penalty-fee`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SetNotificationsEnabled
     * @summary Enable or disable notifications for a given loan
     * @request POST:/api-admin/v1/loans/{id}/notifications-enabled
     * @secure
     */
    setNotificationsEnabled: (
      id: string,
      data: {
        enabled?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api-admin/v1/loans/${id}/notifications-enabled`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SendOverdueNotification
     * @summary Send overdue notification
     * @request POST:/api-admin/v1/loans/{id}/send-overdue-notification
     * @secure
     */
    sendOverdueNotification: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/loans/${id}/send-overdue-notification`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name UpdateDebtProvider
     * @summary Update debt provider
     * @request PATCH:/api-admin/v1/loans/{loanId}/debt-provider
     * @secure
     */
    updateDebtProvider: (loanId: string, data: UpdateDebtProviderDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api-admin/v1/loans/${loanId}/debt-provider`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetLoanInstallments
     * @summary Get loan installments
     * @request GET:/api-admin/v1/loans/{loanId}/installments
     * @secure
     */
    getLoanInstallments: (loanId: string, params: RequestParams = {}) =>
      this.request<InstallmentAdminDto[], any>({
        path: `/api-admin/v1/loans/${loanId}/installments`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name CreateInstallment
     * @summary Create installment
     * @request POST:/api-admin/v1/loans/{loanId}/installments
     * @secure
     */
    createInstallment: (loanId: string, data: UpdateInstallmentDto, params: RequestParams = {}) =>
      this.request<InstallmentAdminDto, any>({
        path: `/api-admin/v1/loans/${loanId}/installments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetLoanServicingActionTypes
     * @summary Get loan servicing action types
     * @request GET:/api-admin/v1/loans/{loanId}/servicing-action-types
     * @secure
     */
    getLoanServicingActionTypes: (loanId: string, params: RequestParams = {}) =>
      this.request<ServicingActionType, void>({
        path: `/api-admin/v1/loans/${loanId}/servicing-action-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetLoanServicingActions
     * @summary Get loan servicing actions
     * @request GET:/api-admin/v1/loans/{loanId}/servicing-actions
     * @secure
     */
    getLoanServicingActions: (loanId: string, params: RequestParams = {}) =>
      this.request<LoanServicingAction, void>({
        path: `/api-admin/v1/loans/${loanId}/servicing-actions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name CreateLoanServicingAction
     * @summary Create loan servicing action
     * @request POST:/api-admin/v1/loans/{loanId}/servicing-actions
     * @secure
     */
    createLoanServicingAction: (loanId: string, data: LoanServicingAction, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api-admin/v1/loans/${loanId}/servicing-actions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOffers1
     * @summary Get offers
     * @request GET:/api-admin/v1/offers
     * @secure
     */
    getOffers1: (params: RequestParams = {}) =>
      this.request<OfferAdminListDto[], any>({
        path: `/api-admin/v1/offers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name CreateOffer
     * @summary Create offer
     * @request POST:/api-admin/v1/offers
     * @deprecated
     * @secure
     */
    createOffer: (data: OfferAdminDto, params: RequestParams = {}) =>
      this.request<Offer, any>({
        path: `/api-admin/v1/offers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name CreateBulkOffers
     * @summary Create bulk offers
     * @request POST:/api-admin/v1/offers/bulk-create
     * @secure
     */
    createBulkOffers: (data: CreateOfferLegacyDto[], params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api-admin/v1/offers/bulk-create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GenerateAggregatedOffer
     * @summary Generate aggregated offer
     * @request POST:/api-admin/v1/offers/customers/{customerId}/generate-aggregated-offer
     * @secure
     */
    generateAggregatedOffer: (customerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/offers/customers/${customerId}/generate-aggregated-offer`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name CreateIndividualOffer
     * @summary Create an individual offer
     * @request POST:/api-admin/v1/offers/individual-create
     * @secure
     */
    createIndividualOffer: (data: CreateOfferDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api-admin/v1/offers/individual-create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOffersList
     * @summary Get offers
     * @request GET:/api-admin/v1/offers/list
     * @secure
     */
    getOffersList: (
      query?: {
        partnerCode?: string
        state?: OfferState
        fulltext?: string
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<OfferAdminListDto[], any>({
        path: `/api-admin/v1/offers/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name CreateSingleOffer
     * @summary Create a single offer
     * @request POST:/api-admin/v1/offers/single-create
     * @secure
     */
    createSingleOffer: (data: CreateSingleOfferDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api-admin/v1/offers/single-create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name LeadUnqualifiedForSingleOffer
     * @summary Set lead as unqualified
     * @request POST:/api-admin/v1/offers/single-unqualified
     * @secure
     */
    leadUnqualifiedForSingleOffer: (data: UnqualifiedSingleOfferDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/offers/single-unqualified`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOffer
     * @summary Get offer
     * @request GET:/api-admin/v1/offers/{id}
     * @secure
     */
    getOffer: (
      id: string,
      query?: {
        includeProducts?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<OfferWithProductsAdminDto, any>({
        path: `/api-admin/v1/offers/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name UpdateOffer
     * @summary Update offer and replace included products
     * @request PUT:/api-admin/v1/offers/{offerId}
     * @secure
     */
    updateOffer: (offerId: string, data: UpdateOfferDto, params: RequestParams = {}) =>
      this.request<Offer, void>({
        path: `/api-admin/v1/offers/${offerId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name DeleteOffer
     * @summary Delete the offer
     * @request DELETE:/api-admin/v1/offers/{offerId}
     * @secure
     */
    deleteOffer: (offerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/offers/${offerId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name UpdateOffer1
     * @summary Update offer
     * @request PATCH:/api-admin/v1/offers/{offerId}
     * @secure
     */
    updateOffer1: (offerId: string, data: OfferAdminDto, params: RequestParams = {}) =>
      this.request<Offer, any>({
        path: `/api-admin/v1/offers/${offerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name PartnerNotification
     * @summary Notify partner
     * @request POST:/api-admin/v1/offers/{offerId}/partner-notification
     * @secure
     */
    partnerNotification: (offerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/offers/${offerId}/partner-notification`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOfferProducts
     * @summary Get offer's products
     * @request GET:/api-admin/v1/offers/{offerId}/products
     * @secure
     */
    getOfferProducts: (offerId: string, params: RequestParams = {}) =>
      this.request<OfferProduct, any>({
        path: `/api-admin/v1/offers/${offerId}/products`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name CreateOfferProduct
     * @summary Create a product within the offer
     * @request POST:/api-admin/v1/offers/{offerId}/products
     * @secure
     */
    createOfferProduct: (offerId: string, data: OfferProductDto, params: RequestParams = {}) =>
      this.request<OfferProduct, any>({
        path: `/api-admin/v1/offers/${offerId}/products`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name DeleteOfferProduct
     * @summary Update product parameters within the offer
     * @request DELETE:/api-admin/v1/offers/{offerId}/products/{offerProductId}
     * @secure
     */
    deleteOfferProduct: (offerId: string, offerProductId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/offers/${offerId}/products/${offerProductId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name UpdateOfferProduct
     * @summary Update product parameters within an offer
     * @request PATCH:/api-admin/v1/offers/{offerId}/products/{offerProductId}
     * @secure
     */
    updateOfferProduct: (offerId: string, offerProductId: string, data: OfferProductDto, params: RequestParams = {}) =>
      this.request<OfferProduct, any>({
        path: `/api-admin/v1/offers/${offerId}/products/${offerProductId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name PublishOffer
     * @summary Publish an offer, that hasn't been yet published due to not met criteria
     * @request PATCH:/api-admin/v1/offers/{offerId}/publish
     * @secure
     */
    publishOffer: (offerId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api-admin/v1/offers/${offerId}/publish`,
        method: 'PATCH',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOfferPublishCriteria
     * @summary Get offer publish criteria
     * @request GET:/api-admin/v1/offers/{offerId}/publish-criteria
     * @secure
     */
    getOfferPublishCriteria: (offerId: string, params: RequestParams = {}) =>
      this.request<OfferPublishCriteriaResponseDto, void>({
        path: `/api-admin/v1/offers/${offerId}/publish-criteria`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Outputs
     * @name SendGridStatusEvent
     * @summary Receive status event from Sendgrid
     * @request POST:/api-admin/v1/outputs/sendgrid-status-event
     * @secure
     */
    sendGridStatusEvent: (data: Record<string, string>[], params: RequestParams = {}) =>
      this.request<DocumentDto, any>({
        path: `/api-admin/v1/outputs/sendgrid-status-event`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Outputs
     * @name TwilioStatusEvent
     * @summary Receive status event from Twilio
     * @request POST:/api-admin/v1/outputs/twilio-status-event
     * @secure
     */
    twilioStatusEvent: (data: Record<string, string>, params: RequestParams = {}) =>
      this.request<DocumentDto, any>({
        path: `/api-admin/v1/outputs/twilio-status-event`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name TwilioTest
     * @request POST:/api-admin/v1/outputs/twilio-test/{applicationId}
     * @secure
     */
    twilioTest: (applicationId: string, params: RequestParams = {}) =>
      this.request<DocumentDto, any>({
        path: `/api-admin/v1/outputs/twilio-test/${applicationId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetPartyList
     * @summary Get parties
     * @request GET:/api-admin/v1/parties
     * @secure
     */
    getPartyList: (params: RequestParams = {}) =>
      this.request<CustomerListItemDto[], any>({
        path: `/api-admin/v1/parties`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateParty
     * @summary Create a party
     * @request POST:/api-admin/v1/parties
     * @secure
     */
    createParty: (data: PartyAdminDto, params: RequestParams = {}) =>
      this.request<Party, any>({
        path: `/api-admin/v1/parties`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCountries
     * @summary Get countries
     * @request GET:/api-admin/v1/parties/countries
     * @secure
     */
    getCountries: (params: RequestParams = {}) =>
      this.request<CountryDto[], any>({
        path: `/api-admin/v1/parties/countries`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetPartyList1
     * @summary Get parties
     * @request GET:/api-admin/v1/parties/new
     * @secure
     */
    getPartyList1: (
      query?: {
        type?: PartyType
        country?: string
        partnerCode?: string
        customersRelatedOnly?: boolean
        fulltext?: string
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<CustomerListItemDto[], any>({
        path: `/api-admin/v1/parties/new`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetParty
     * @summary Get party details
     * @request GET:/api-admin/v1/parties/{id}
     * @secure
     */
    getParty: (id: string, params: RequestParams = {}) =>
      this.request<CustomerDto, any>({
        path: `/api-admin/v1/parties/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name DeleteParty
     * @summary Delete party
     * @request DELETE:/api-admin/v1/parties/{id}
     * @secure
     */
    deleteParty: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/parties/${id}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateParty
     * @summary Update party
     * @request PATCH:/api-admin/v1/parties/{id}
     * @secure
     */
    updateParty: (id: string, data: PartyAdminDto, params: RequestParams = {}) =>
      this.request<Party, any>({
        path: `/api-admin/v1/parties/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetPartyKycVerifications
     * @summary Get party KYC verifications
     * @request GET:/api-admin/v1/parties/{id}/kyc-verifications
     * @secure
     */
    getPartyKycVerifications: (id: string, params: RequestParams = {}) =>
      this.request<KycVerification, any>({
        path: `/api-admin/v1/parties/${id}/kyc-verifications`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name SetKycVerified
     * @summary Set KYC verification as APPROVED
     * @request POST:/api-admin/v1/parties/{id}/set-kyc-verified
     * @secure
     */
    setKycVerified: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/parties/${id}/set-kyc-verified`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateOwner
     * @summary Create owner
     * @request POST:/api-admin/v1/parties/{orgId}/owners
     * @secure
     */
    createOwner: (orgId: string, data: ModifyOwnerDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api-admin/v1/parties/${orgId}/owners`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name UpdateOwner
     * @request PUT:/api-admin/v1/parties/{orgId}/owners/{ownerId}
     * @secure
     */
    updateOwner: (orgId: string, ownerId: string, data: ModifyOwnerDto, params: RequestParams = {}) =>
      this.request<OwnerDto, any>({
        path: `/api-admin/v1/parties/${orgId}/owners/${ownerId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateOwner1
     * @summary Update owner
     * @request PATCH:/api-admin/v1/parties/{orgId}/owners/{ownerId}
     * @secure
     */
    updateOwner1: (orgId: string, ownerId: string, data: UpdateOwnerDto, params: RequestParams = {}) =>
      this.request<OwnerDto, any>({
        path: `/api-admin/v1/parties/${orgId}/owners/${ownerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetList1
     * @summary Get the list of partner connections for a given customer
     * @request GET:/api-admin/v1/partner-connections/customers/{id}
     * @secure
     */
    getList1: (id: string, params: RequestParams = {}) =>
      this.request<PartnerConnectionDto[], any>({
        path: `/api-admin/v1/partner-connections/customers/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetPartners
     * @summary Get partners
     * @request GET:/api-admin/v1/partners
     * @secure
     */
    getPartners: (
      query?: {
        includePartyAttrs?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<PartnerDto[], any>({
        path: `/api-admin/v1/partners`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreatePartner
     * @summary Create partner
     * @request POST:/api-admin/v1/partners
     * @secure
     */
    createPartner: (data: PartnerUpdateDto, params: RequestParams = {}) =>
      this.request<PartnerDto, any>({
        path: `/api-admin/v1/partners`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name SetOnBehalf1
     * @summary Set user on behalf
     * @request POST:/api-admin/v1/partners/{customerId}/representatives/on-behalf
     * @secure
     */
    setOnBehalf1: (customerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/partners/${customerId}/representatives/on-behalf`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name RemoveOnBehalf1
     * @summary Remove user on behalf
     * @request DELETE:/api-admin/v1/partners/{customerId}/representatives/on-behalf
     * @secure
     */
    removeOnBehalf1: (customerId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/partners/${customerId}/representatives/on-behalf`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetPartner
     * @summary Get partner details
     * @request GET:/api-admin/v1/partners/{id}
     * @secure
     */
    getPartner: (id: string, params: RequestParams = {}) =>
      this.request<PartnerDto, any>({
        path: `/api-admin/v1/partners/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name DeletePartner
     * @summary Delete partner
     * @request DELETE:/api-admin/v1/partners/{id}
     * @secure
     */
    deletePartner: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/partners/${id}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdatePartner
     * @summary Update partner
     * @request PATCH:/api-admin/v1/partners/{id}
     * @secure
     */
    updatePartner: (id: string, data: PartnerUpdateDto, params: RequestParams = {}) =>
      this.request<PartnerDto, any>({
        path: `/api-admin/v1/partners/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetPartnerCommissionSchemas
     * @summary Get partner's commission schemas
     * @request GET:/api-admin/v1/partners/{id}/commission-schemas
     * @secure
     */
    getPartnerCommissionSchemas: (id: string, params: RequestParams = {}) =>
      this.request<CommissionSchema[], any>({
        path: `/api-admin/v1/partners/${id}/commission-schemas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateCommissionSchema
     * @summary Create commission schema
     * @request POST:/api-admin/v1/partners/{partnerId}/commission-schemas
     * @secure
     */
    createCommissionSchema: (partnerId: string, data: CommissionSchemaUpdateDto, params: RequestParams = {}) =>
      this.request<CommissionSchema, any>({
        path: `/api-admin/v1/partners/${partnerId}/commission-schemas`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name DeleteCommissionSchema
     * @summary Delete commission schema
     * @request DELETE:/api-admin/v1/partners/{partnerId}/commission-schemas/{id}
     * @secure
     */
    deleteCommissionSchema: (partnerId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/partners/${partnerId}/commission-schemas/${id}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateCommissionSchema
     * @summary Update commission schema
     * @request PATCH:/api-admin/v1/partners/{partnerId}/commission-schemas/{id}
     * @secure
     */
    updateCommissionSchema: (
      partnerId: string,
      id: string,
      data: CommissionSchemaUpdateDto,
      params: RequestParams = {}
    ) =>
      this.request<CommissionSchema, any>({
        path: `/api-admin/v1/partners/${partnerId}/commission-schemas/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetApplications1
     * @summary Get applications
     * @request GET:/api-admin/v1/persons/{personId}/applications
     * @secure
     */
    getApplications1: (personId: string, params: RequestParams = {}) =>
      this.request<ApplicationAdminListItemDto[], any>({
        path: `/api-admin/v1/persons/${personId}/applications`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetOffers2
     * @summary Get offers
     * @request GET:/api-admin/v1/persons/{personId}/offers
     * @secure
     */
    getOffers2: (personId: string, params: RequestParams = {}) =>
      this.request<OfferAdminListDto[], any>({
        path: `/api-admin/v1/persons/${personId}/offers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Risk Evaluations
     * @name TriggerBatchImport
     * @summary Trigger batch import of risk evaluations & revenue data
     * @request POST:/api-admin/v1/risk-evaluations/batch
     * @secure
     */
    triggerBatchImport: (data: TriggerRiskEvaluationsImportDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api-admin/v1/risk-evaluations/batch`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Risk Evaluations
     * @name GetBusinessDataByCustomerId
     * @summary Get all business data by customer ID
     * @request GET:/api-admin/v1/risk-evaluations/business-data/customer/{customerId}
     * @secure
     */
    getBusinessDataByCustomerId: (customerId: string, params: RequestParams = {}) =>
      this.request<RiskEvaluation[], any>({
        path: `/api-admin/v1/risk-evaluations/business-data/customer/${customerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Risk Evaluations
     * @name GetRecentBusinessDataByOfferId
     * @summary Get recent risk evaluations (Business Data & Open Banking) by offer ID & customer ID
     * @request GET:/api-admin/v1/risk-evaluations/customer/{customerId}/offer/{offerId}
     * @secure
     */
    getRecentBusinessDataByOfferId: (customerId: string, offerId: string, params: RequestParams = {}) =>
      this.request<OfferRiskEvaluationsOutputDto, void>({
        path: `/api-admin/v1/risk-evaluations/customer/${customerId}/offer/${offerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Risk Evaluations
     * @name GetOpenBankingByCustomerId
     * @summary Get all open banking data by customer ID
     * @request GET:/api-admin/v1/risk-evaluations/open-banking-data/customer/{customerId}
     * @secure
     */
    getOpenBankingByCustomerId: (customerId: string, params: RequestParams = {}) =>
      this.request<RiskEvaluation[], any>({
        path: `/api-admin/v1/risk-evaluations/open-banking-data/customer/${customerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Scorings
     * @name GetCustomerScoringByApplicationId
     * @summary Get customer scoring by applicationId
     * @request GET:/api-admin/v1/scorings/application/{applicationId}
     * @secure
     */
    getCustomerScoringByApplicationId: (applicationId: string, params: RequestParams = {}) =>
      this.request<CustomerScoring, any>({
        path: `/api-admin/v1/scorings/application/${applicationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Scorings
     * @name GetCustomerScoringsByCustomerId
     * @summary Get customer scorings by customerId, ordered by createdAt asc
     * @request GET:/api-admin/v1/scorings/customer/{customerId}
     * @secure
     */
    getCustomerScoringsByCustomerId: (customerId: string, params: RequestParams = {}) =>
      this.request<CustomerScoring, any>({
        path: `/api-admin/v1/scorings/customer/${customerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Scorings
     * @name GetCustomerScoringById
     * @summary Get customer scoring details
     * @request GET:/api-admin/v1/scorings/{id}
     * @secure
     */
    getCustomerScoringById: (id: string, params: RequestParams = {}) =>
      this.request<CustomerScoring, any>({
        path: `/api-admin/v1/scorings/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Scorings
     * @name PerformCustomerScoringAction
     * @summary Performs given underwriting action
     * @request POST:/api-admin/v1/scorings/{id}/action
     * @secure
     */
    performCustomerScoringAction: (id: string, data: CustomerScoringActionRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/scorings/${id}/action`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @name GetCustomerScoringTree
     * @request GET:/api-admin/v1/scorings/{id}/parties
     * @secure
     */
    getCustomerScoringTree: (id: string, params: RequestParams = {}) =>
      this.request<CustomerScoringTreeDto, any>({
        path: `/api-admin/v1/scorings/${id}/parties`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name GetRiskProfileDetailForCustomerScoringAndParty
     * @request GET:/api-admin/v1/scorings/{id}/parties/{partyId}
     * @secure
     */
    getRiskProfileDetailForCustomerScoringAndParty: (id: string, partyId: string, params: RequestParams = {}) =>
      this.request<RiskProfileDetailDto, any>({
        path: `/api-admin/v1/scorings/${id}/parties/${partyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Scorings
     * @name GetRiskProfileForCustomerScoring
     * @summary Get customer's risk profile for given scoring
     * @request GET:/api-admin/v1/scorings/{id}/risk-profile
     * @secure
     */
    getRiskProfileForCustomerScoring: (id: string, params: RequestParams = {}) =>
      this.request<RiskProfile, any>({
        path: `/api-admin/v1/scorings/${id}/risk-profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name GetTermsList
     * @summary Get terms & conditions
     * @request GET:/api-admin/v1/terms
     * @secure
     */
    getTermsList: (params: RequestParams = {}) =>
      this.request<TermsDto[], any>({
        path: `/api-admin/v1/terms`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name CreateTerms
     * @summary Create terms
     * @request POST:/api-admin/v1/terms
     * @secure
     */
    createTerms: (
      data: {
        revision: string
        /** Countries supported by FlowPay */
        country: FlowpayCountry
        /** @format date */
        effectiveFrom: string
        /** @format binary */
        file: File
      },
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/api-admin/v1/terms`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name GetFile
     * @summary Get file for a given terms & condition
     * @request GET:/api-admin/v1/terms/{id}
     * @secure
     */
    getFile: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api-admin/v1/terms/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name DeleteTerms
     * @summary Delete terms
     * @request DELETE:/api-admin/v1/terms/{id}
     * @secure
     */
    deleteTerms: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/terms/${id}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name UpdateTerms
     * @summary Update terms
     * @request PATCH:/api-admin/v1/terms/{id}
     * @secure
     */
    updateTerms: (
      id: string,
      data: {
        revision: string
        /** Countries supported by FlowPay */
        country: FlowpayCountry
        /** @format date */
        effectiveFrom: string
        /** @format binary */
        file?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<TermsDto, any>({
        path: `/api-admin/v1/terms/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name GetTransactionList
     * @summary Get transactions
     * @request GET:/api-admin/v1/transactions
     * @secure
     */
    getTransactionList: (params: RequestParams = {}) =>
      this.request<BankTransaction[], any>({
        path: `/api-admin/v1/transactions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name CreateTransaction
     * @summary Create transaction
     * @request POST:/api-admin/v1/transactions
     * @secure
     */
    createTransaction: (data: CreateBankTransactionDto, params: RequestParams = {}) =>
      this.request<BankTransaction, any>({
        path: `/api-admin/v1/transactions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name GetTransactionCategories
     * @summary Get transaction categories
     * @request GET:/api-admin/v1/transactions/categories
     * @secure
     */
    getTransactionCategories: (params: RequestParams = {}) =>
      this.request<OpenBankingCategoriesDto, any>({
        path: `/api-admin/v1/transactions/categories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name GetTransactionsAdminList
     * @summary Get transactions admin list
     * @request GET:/api-admin/v1/transactions/list
     * @secure
     */
    getTransactionsAdminList: (
      query?: {
        state?: BankTransactionState
        /** ISO 4217 alpha-3 currency code enum, including metadata. */
        currency?: IsoCurrencyCode
        iban?: string
        fulltext?: string
        pageable?: Pageable
      },
      params: RequestParams = {}
    ) =>
      this.request<BankTransaction[], any>({
        path: `/api-admin/v1/transactions/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name DoRollbackBankTransaction
     * @summary Rollback transaction
     * @request POST:/api-admin/v1/transactions/{id}/rollback
     * @secure
     */
    doRollbackBankTransaction: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/transactions/${id}/rollback`,
        method: 'POST',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name DeleteTransaction
     * @summary Delete transaction
     * @request DELETE:/api-admin/v1/transactions/{txnId}
     * @secure
     */
    deleteTransaction: (txnId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api-admin/v1/transactions/${txnId}`,
        method: 'DELETE',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name UpdateTransaction
     * @summary Update transaction
     * @request PATCH:/api-admin/v1/transactions/{txnId}
     * @secure
     */
    updateTransaction: (txnId: string, data: UpdateBankTransactionDto, params: RequestParams = {}) =>
      this.request<BankTransaction, any>({
        path: `/api-admin/v1/transactions/${txnId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name GetPairedCollectionFees
     * @summary Get collection fees paired with a transaction
     * @request GET:/api-admin/v1/transactions/{txnId}/collection-fees
     * @secure
     */
    getPairedCollectionFees: (txnId: string, params: RequestParams = {}) =>
      this.request<RelTxnCollectionFee[], any>({
        path: `/api-admin/v1/transactions/${txnId}/collection-fees`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name GetPairedInstallments
     * @summary Get installments paired with a transaction
     * @request GET:/api-admin/v1/transactions/{txnId}/installments
     * @secure
     */
    getPairedInstallments: (txnId: string, params: RequestParams = {}) =>
      this.request<RelTxnInstallment[], any>({
        path: `/api-admin/v1/transactions/${txnId}/installments`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetTxnBasicDashboardData
     * @summary Get TXN Basic Overview dashboard
     * @request GET:/api-admin/v1/txn/customers/{customerId}/dashboard/basic
     * @secure
     */
    getTxnBasicDashboardData: (
      customerId: string,
      query: {
        /** @format date */
        from: string
        /** @format date */
        till: string
        accountIds: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<TxnBasicDashboardDto, any>({
        path: `/api-admin/v1/txn/customers/${customerId}/dashboard/basic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetTxnDetailedCategoriesDashboardData
     * @summary Get TXN Detailed Categories dashboard
     * @request GET:/api-admin/v1/txn/customers/{customerId}/dashboard/category
     * @secure
     */
    getTxnDetailedCategoriesDashboardData: (
      customerId: string,
      query: {
        /** @format date */
        from: string
        /** @format date */
        till: string
        accountIds: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<TxnDetailedCategoriesDashboardDto, any>({
        path: `/api-admin/v1/txn/customers/${customerId}/dashboard/category`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetTxnDetailedDashboardData
     * @summary Get TXN Detailed Overview dashboard
     * @request GET:/api-admin/v1/txn/customers/{customerId}/dashboard/detail
     * @secure
     */
    getTxnDetailedDashboardData: (
      customerId: string,
      query: {
        accountIds: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<TxnDetailedOverviewDashboardDto, any>({
        path: `/api-admin/v1/txn/customers/${customerId}/dashboard/detail`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetTxnDetailInfoDashboardHeader
     * @summary Get TXN dashboard header
     * @request GET:/api-admin/v1/txn/customers/{customerId}/dashboard/header
     * @secure
     */
    getTxnDetailInfoDashboardHeader: (customerId: string, params: RequestParams = {}) =>
      this.request<TxnDashboardHeaderDto, any>({
        path: `/api-admin/v1/txn/customers/${customerId}/dashboard/header`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetTxnDetailInfoDashboardData
     * @summary Get TXN Detailed Information
     * @request GET:/api-admin/v1/txn/customers/{customerId}/dashboard/info
     * @secure
     */
    getTxnDetailInfoDashboardData: (
      customerId: string,
      query: {
        /** @format date */
        from: string
        /** @format date */
        till: string
        accountIds: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<TxnDetailInfoDashboardDto, any>({
        path: `/api-admin/v1/txn/customers/${customerId}/dashboard/info`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name GetAdminUserProfile
     * @summary Get current user's profile
     * @request GET:/api-admin/v1/users/current
     * @secure
     */
    getAdminUserProfile: (params: RequestParams = {}) =>
      this.request<UserAdminDto, any>({
        path: `/api-admin/v1/users/current`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name GetInternalUsers
     * @summary Get internal users
     * @request GET:/api-admin/v1/users/internal
     * @secure
     */
    getInternalUsers: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/api-admin/v1/users/internal`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name CreateInternalUser
     * @summary Create internal/admin user
     * @request POST:/api-admin/v1/users/internal
     * @secure
     */
    createInternalUser: (data: InternalUserCreateDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api-admin/v1/users/internal`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Internal
     * @name InternalUserSignup
     * @summary Signup of internal user in IAM
     * @request POST:/api-admin/v1/users/internal-signup
     * @secure
     */
    internalUserSignup: (data: InternalUserSignupDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api-admin/v1/users/internal-signup`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name Link
     * @summary Link user identities.
     * @request POST:/api-admin/v1/users/link
     * @secure
     */
    link: (data: UserLinkDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api-admin/v1/users/link`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Common
     * @name Signup
     * @summary Create a user in the backend system after successful email verification.
     * @request POST:/api-admin/v1/users/signup
     * @secure
     */
    signup: (data: UserSignUpDto, params: RequestParams = {}) =>
      this.request<UserCreateResponseDto, any>({
        path: `/api-admin/v1/users/signup`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  api = {
    /**
     * No description
     *
     * @tags Signi
     * @name WebhookComplete
     * @summary Process webhook (Complete) from Signi
     * @request POST:/api/v1/signi/webhookComplete
     * @secure
     */
    webhookComplete: (data: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/signi/webhookComplete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  test = {
    /**
     * No description
     *
     * @name TestRoundtrip
     * @request GET:/test/db/roundtrip
     * @secure
     */
    testRoundtrip: (params: RequestParams = {}) =>
      this.request<DbTestResourceTestOutputRecord, any>({
        path: `/test/db/roundtrip`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name TestRoundtrip1
     * @request GET:/test/db/roundtrip/{scale}
     * @secure
     */
    testRoundtrip1: (scale: number, params: RequestParams = {}) =>
      this.request<DbTestResourceTestOutputRecord, any>({
        path: `/test/db/roundtrip/${scale}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      })
  }
}
