// ** React Imports
import { useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Hook Imports
import { useAuth0 } from '@auth0/auth0-react'

/**
 *  Set Home URL based on User Roles
 */
export const getHomeRoute = (role: string) => {
  return '/dashboard'
}

const Home = () => {
  // ** Hooks
	const { isLoading, isAuthenticated, user, error, loginWithRedirect, logout } = useAuth0();
  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    if (isAuthenticated && user) {
      const homeRoute = getHomeRoute('')

      // Redirect user to Home URL
      router.replace(homeRoute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

	return <Spinner />
}

export default Home
