import { SvgIconProps } from '@mui/material/SvgIcon'
import CustomSvgIcon from './custom-svg-icon'


const CardAccountDetailsOutline = (props: SvgIconProps) => {
  return (
    <CustomSvgIcon
      {...props}
      path='M22,3H2C0.91,3.04 0.04,3.91 0,5V19C0.04,20.09 0.91,20.96 2,21H22C23.09,20.96 23.96,20.09 24,19V5C23.96,3.91 23.09,3.04 22,3M22,19H2V5H22V19M14,17V15.75C14,14.09 10.66,13.25 9,13.25C7.34,13.25 4,14.09 4,15.75V17H14M9,7A2.5,2.5 0 0,0 6.5,9.5A2.5,2.5 0 0,0 9,12A2.5,2.5 0 0,0 11.5,9.5A2.5,2.5 0 0,0 9,7M14,7V8H20V7H14M14,9V10H20V9H14M14,11V12H18V11H14'
    />
  )
}

export default CardAccountDetailsOutline
